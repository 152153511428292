import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getMonitoringData } from '../../API/monitorApi';

export const callGetMonitoringData = createAsyncThunk(
    'monitor/getMonitoringData',
    async (payload, { dispatch }) => {
        const response = await getMonitoringData(payload);
        dispatch(setMonitoringData(response.data));
    }
);

export const monitorSlice = createSlice({
  name: 'monitor',
  initialState: {
    monitoringData: []
  },
  reducers: {
    setMonitoringData: (state, action) => { state.monitoringData = action.payload }
  }
});

export const { setMonitoringData } = monitorSlice.actions;

export const selectMonitoringData = state => state.monitor.monitoringData;

export default monitorSlice.reducer;
