import './CampaignDefinitionResults.scss';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import Button from "@mui/material/Button";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Autocomplete, Checkbox, FormControlLabel, TextField} from "@mui/material";
import CampaignDefinitionTextField from "./CampaignDefinitionTextField";
import {AgGridReact} from "ag-grid-react";
import {twoDecimalFormatter} from "../../../common/Utils";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {priorities} from "../../../constants/picklists";
import ConfirmPriorityDialog from "./ConfirmPriorityDialog";
import { debounce } from 'lodash';
import {
  getSalesforceUsers,
  submitCampaign,
  submitForResearch,
  exportPropertyLeadsAsCsv, exportOwnerLeadsAsCsv
} from "../../../../main/API/leadMakerSearchApi";
import CampaignDefinitionSelectField from "./CampaignDefinitionSelectField";
import {getCampaign} from "../../../../main/API/leadMakerSearchApi";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {
  selectActiveSearchParams,
  selectGeographicResultType, selectGridBlockSize, selectSelectAllChecked, setSelectAllCheckbox, setGridBlockSize
} from "../../../../main/store/slices/leadMakerSearchSlice";
import {
  leadMakerSearchResultsDatasourceFn
} from "./CampaignDefinitionResultsDatasource";

import {setToastMessage} from "../../../../main/store/slices/utilSlice";
import toastTypes from "../../../constants/toastTypes";
import {useDispatch} from "react-redux";
import {geographicResultType} from "../../../constants/GeographicSearchConstants";

const CampaignDefinitionResults = () => {
  
  const dispatch = useDispatch();
  
  const bufferRows = 10;
  const gridRowsVisible = 9;
  
  const submitTypes = {
    ALL_RESEARCH : 0,
    OWNER_RESEARCH : 1,
    ROOF_RESEARCH : 2,
  }

  const defaultPriority = priorities[2].id;

  const clearedPayload = {
    campaignId : "",
    campaignName : "",
    salesforceUserId : "",
    addOtherSalesRepsOwnedLeadsToCampaign : false,
  }
  
  const allColumns = [
      {field: 'name', headerName: 'Lead Name', width: 200, headerCheckboxSelection: true, checkboxSelection: true},
      {field: 'company', headerName: 'Lead Company', width: 250 },
      {field: 'ownerState', headerName: 'Owner State', width: 80, cellClass: "grid-cell-centered" },
      {field: 'city', headerName: 'Property City', width: 120, cellClass: "grid-cell-centered"},
      {field: 'propertyState', headerName: 'Property State', width: 110, cellClass: "grid-cell-centered" },
      {field: 'roofSF', headerName: 'Roof SF', width: 100, cellClass: "grid-cell-centered" },
      {field: 'roofType', headerName: 'Roof Type', width: 100, cellClass: "grid-cell-centered" },
      {field: 'hailSize', headerName: 'Hail Size At Location', width: 125, cellClass: "grid-cell-centered",
        valueFormatter: twoDecimalFormatter },
      {field: 'TTLProperties', headerName: 'TTL Properties', width: 110, cellClass: "grid-cell-centered" },
  ]
  
  const checkIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [ selectAllChecked, setSelectAllChecked ] = useState(false);
  const [ selectedAllMessage, setSelectedAllMessage ] = useState("");
  const [ selectMoreMessage, setSelectMoreMessage ] = useState("");
  const [ clearSelectionMessage, setClearSelectionMessage ] = useState("");
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ submittedRows, setSubmittedRows ] = useState([]);
  const [ submitDestination, setSubmitDestination ] = useState();
  const [ selectedPriority, setSelectedPriority ] = useState(defaultPriority);
  const [ salesforceUsers, setSalesforceUsers ]  = useState([]);
  const [ selectionCount, setSelectionCount ] = useState(0);
  
  const [payload, setPayload] = useState(clearedPayload);
  const [errors, setErrors] = useState({});

  const currentGeographicResultType = useSelector(selectGeographicResultType);

  const {
    searchParams,
    gridBlockSize
  } = useSelector((state) => ({
    searchParams: selectActiveSearchParams(state),
    gridBlockSize: selectGridBlockSize(state)
  }))

  const [datasource, setDatasource] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  
  // Reset the datasource when the search params change
  useEffect(() => {
    if (searchParams != null) {
      const datasource = leadMakerSearchResultsDatasourceFn(searchParams, setTotalRows)
      setDatasource(datasource);
    }
  }, [searchParams])
  
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([
    {field: 'leadName', headerName: 'Lead Name', flex: 2, minWidth: 130, checkboxSelection: true },
    {field: 'leadCompany', headerName: 'Lead Company', flex: 2, minWidth: 100 },
    {field: 'ownerState', headerName: 'Owner State', width: 80, cellClass: "grid-cell-centered" },
    {field: 'propertyCity', headerName: 'Property City', flex: 1, minWidth: 100, cellClass: "grid-cell-centered"},
    {field: 'propertyState', headerName: 'Property State', width: 110, cellClass: "grid-cell-centered" },
    {field: 'roofSquareFeet', headerName: 'Roof SF', width: 100, cellClass: "grid-cell-centered" },
    {field: 'roofType', headerName: 'Roof Type', width: 100, cellClass: "grid-cell-centered" },
    {field: 'maxHailSize', headerName: 'Hail Size At Location', width: 125, cellClass: "grid-cell-centered",
                        valueFormatter: twoDecimalFormatter },
    {field: 'ttlProperties', headerName: 'TTL Properties', width: 110, cellClass: "grid-cell-centered" },
  ]);
  
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  }));
  
  useEffect(() => {
    async function fetchSalesforceUsers() {
      const {data} = await getSalesforceUsers("");
      const results = [];
      data?.returnObject?.forEach((value) => {
        results.push({
          id: value.id,
          value: value.firstName.concat(" ", value.lastName),
        });
      });
      setSalesforceUsers(results?.sort(function(a,b) {
        return a.value.localeCompare(b.value);
      }));
    }

    fetchSalesforceUsers();
  }, []);

  const onSelectionChanged = debounce((params) => {
    setSelectionCount(params.api.getSelectedRows()?.length)
  })
  
  const handleChange = (value, fieldName) => {
    // console.log('new value is ', value);
    // console.log(fieldName + " was changed to: " + value);
      setPayload((payload) => ({...payload, [fieldName]: value }));
  }
  
  const validateCampaignId = async (campaignId) => {
    const campaignName = await getCampaign(campaignId.trim());
    if (campaignName) {
      setPayload((payload) => ({...payload, campaignName: campaignName}));
      const { campaignId, ...newErrors } = errors;
      setErrors(newErrors);
      return true;
    }
    else {
      setPayload((payload) => ({...payload, campaignName: "Not Found"}));
      setErrors({ ...errors, campaignId : "Campaign Id is not valid"});
      return false;
    }
  }
  
  const campaignSchema = Yup.object().shape({
    campaignId: Yup.string()
      .required("Campaign Id is required"),
    salesforceUserId: Yup.string()
      .required("Salesforce user must be specified")
  });
  
  const validatePayload = async () => {
    let newErrors = {};
    try {
      campaignSchema.validateSync(payload, {abortEarly: false});
    } catch (error) {
      newErrors = error?.inner.reduce((acc, cur) => {
        acc[cur.path] = cur.message;
        return acc;
      }, {});
    }
    // only validate campaignId if everything else is good
    if (Object.keys(newErrors).length === 0) {
      let campaignIdGood = await validateCampaignId(payload.campaignId);
      console.log('campaignIdGood: ', campaignIdGood);
      if (!campaignIdGood) {
        newErrors = {
          campaignId: "Must enter a valid CampaignId",
        }
      }
    }
    return newErrors;
  }
  
  const submitCampaignPayload = async (payload) => {
    const campaignPayload = gridRef.current.api.getSelectedRows()
      .map(row => ({
        ownerId: row.ownerId,
        propertyId: row.propertyId,
        campaignId: payload.campaignId,
        salesforceUserId: payload.salesforceUserId,
        propertySalesforceId: row.salesforceId,
        addOtherSalesRepsOwnedLeadsToCampaign: payload.AddOtherSalesRepsOwnedLeadsToCampaign,
      }));
    let response = {};
    try {
      response = await submitCampaign(campaignPayload);
    } catch(error) {
      console.error(`Error: ${error}`);
      return {
        success: false,
        exceptionMessage : error.message,
      };
    }
    return response.data;
  }
  
  const submitSearchPayload =  async () => {
    // console.log('selected rows: ', gridRef.current.api.getSelectedRows());
    if (gridRef.current.api.getSelectedRows().length === 0) {
      dispatch(setToastMessage({
        message: `No properties selected for campaign`,
        type: toastTypes.error
      }))
      return
    }
    let formErrors = await validatePayload();
    if (!formErrors || (Object.keys(formErrors).length === 0)) {
      let responseData =  await submitCampaignPayload(payload);
      if (responseData.success) {
        dispatch(setToastMessage({
          message: `Leads and Assets queued for creation and Campaign population`,
          type: toastTypes.success
        }))
      } else {
        dispatch(setToastMessage({
          message: `Problem submitting leads/assets: ${responseData.exceptionMessage}`,
          type: toastTypes.error
        }))
      }
      
    } else {
      dispatch(setToastMessage({
        message: `Unable to submit leads/assets -- see errors in form`,
        type: toastTypes.error
      }))
    }
    setErrors(formErrors);
  }

  const submitSelectedRows = (dest) => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    if (selectedRows && selectedRows.length > 0) {
      setSubmittedRows(selectedRows);
      setSubmitDestination(dest);
      setDialogOpen(true);
    }
    else {
      alert("No rows selected for submission");
    }
  };
  
  const submitConfirmed = async () => {
    const payload = submittedRows.map(row => {
        return {
          ownerId: row.ownerId,
          propertyId: row.propertyId,
          priorityLevel: parseInt(selectedPriority),
          researchType: submitDestination,
        }
    })

    submitForResearch(payload).then((response) => {
      const unsubmittedRowCount = response.data.returnObject.length;
      if(unsubmittedRowCount === 0) {
        alert(`Successfully submitted ${submittedRows.length} properties for research`);
      } else {
        alert(`${submittedRows.length - unsubmittedRowCount} properties were updated, but ${unsubmittedRowCount} were unable to be submitted.`);
      }
    }).catch((error) => {
      alert("Error submitting for research: " + error.message);
    });

    // Reset the selected priority to the default
    setSelectedPriority(defaultPriority);
  }
  
  const exportGridAsCSV = async () => {
    const resultTypeId = parseInt(currentGeographicResultType);
    const selectedRows = gridRef.current.api.getSelectedRows();

    //If no rows are selected, alert the user and return early
    if(selectedRows.length === 0) {
        alert("No rows selected for export");
        return;
    }

    if(resultTypeId === geographicResultType.PROPERTY.id) {
      await exportPropertyLeadsAsCsv(selectedRows.map(row => row.propertyId));
    } else if(resultTypeId === geographicResultType.OWNER.id) {
      await exportOwnerLeadsAsCsv(selectedRows.map(row => row.ownerId));
    }
  }
  
  const adjustRowsForWindowSize = (first, last) => {
    // fix first and last based on what's actually visible (remove rowbuffers reported by grid)
    let rowsToShow = last-first+1;
    if (rowsToShow <= gridRowsVisible) {
      return [ first, last ];
    }
    // more than the visible size - just try and remove the buffer
    if (rowsToShow === (gridRowsVisible + 2 * bufferRows)) {
      // we in the middle -- just remove the buffers
      return [ first + bufferRows, last - bufferRows - 1 ];
    }
    // we're at one of the ends -- figure out which one
    if (first === 0) {
      return [ last-bufferRows-gridRowsVisible+1, last-bufferRows ];
    } else {
      // we're at the other end
      return [ first+bufferRows, first+bufferRows+gridRowsVisible];
    }
  }

  const handleForceLoadAllData = () => {
    // gridRef.current.api.setDatasource(undefined);
    setDatasource(undefined);
    setSelectAllChecked(false);
    dispatch(setGridBlockSize(totalRows));
    setTimeout(() => {
      setDatasource(leadMakerSearchResultsDatasourceFn(searchParams, setTotalRows))
    }, 500)
  }

  const handleSelectAll = (checked) => {
    setSelectAllChecked(checked);
    gridRef.current?.api.forEachNode(function (node, index) {
        node.setSelected(checked);
    });
  }
  
  const setColumns = (newValue) => {
    setColumnDefs(newValue);
  }
  
  return (
    <>
      <div className="campaign-definition-results-wrapper">
        <div className="form-row" >
          <div className="form-control row ">
            <div className="form-control row campaign-definition-button-row" >
              <div >
                <Autocomplete
                  multiple
                  id="select-columns"
                  options={allColumns}
                  value={columnDefs}
                  onChange={(event, newValue) => {
                    setColumns(newValue);
                  }}
                  size="small"
                  limitTags={1}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option.headerName === value.headerName}
                  getOptionLabel={(option) => option.headerName || ""}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} style={{ height: "1.5em" }}>
                      <Checkbox
                        icon={checkIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 2}}
                        size="small"
                        checked={selected}
                      />
                      {option.headerName}
                    </li>
                  )}
                  style={{ width: 300 }}
                  renderInput={(params) => {
                    return (
                    <TextField {...params}
                       size="small"
                       label="Select Columns"
                    />
                  )}}
                />
              </div>
              <div className="campaign-definition-fill-space">&nbsp;</div>
              <div className="campaign-definition-button-container">
                <Button variant='outlined'
                        disabled={gridRef.current == null}
                        onClick={() => {exportGridAsCSV()}}
                        size="small">Export to CSV</Button>
                <Button variant='outlined'
                        disabled={gridRef.current == null}
                        onClick={() => {submitSelectedRows(submitTypes.ALL_RESEARCH)}}
                        size="small"
                        color='error'>Submit for All Research</Button>
                <Button variant='outlined'
                        disabled={gridRef.current == null}
                        onClick={() => {submitSelectedRows(submitTypes.ROOF_RESEARCH)}}
                        size="small"
                        color='error'>Submit for Roof Research</Button>
                <Button variant='outlined'
                        disabled={gridRef.current == null}
                        onClick={() => {submitSelectedRows(submitTypes.OWNER_RESEARCH)}}
                        size="small"
                        color='error'>Submit for Owner Research</Button>
              </div>
            </div>
          </div>
          <ConfirmPriorityDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            title="Choose Priority for selected items:"
            setPriority={setSelectedPriority}
            onConfirm={submitConfirmed}
          />
          {datasource !== null && <div className="campaign-definition-select-all">
            <FormControlLabel
              control={<Checkbox checked={selectAllChecked}
                                 onChange={(e) =>
                                   handleSelectAll(e.target.checked, true)}/>}
              label={<span style={{ fontSize: "0.8rem"}}>Select All Loaded Records</span>}
              labelPlacement="end"
            />
            <Button variant="text" onClick={handleForceLoadAllData}>Force Load All Data</Button>
            {!!selectionCount && <span>Selected Count: {selectionCount}</span>}
          </div>}
          <div className="ag-theme-alpine form-control row" style={{ height: 320}}>
            {datasource !== null ?
            <AgGridReact
              ref={gridRef}
              rowHeight="30"
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressMultiSort
              suppressRowClickSelection={true}
              animateRows={true}
              rowSelection='multiple'
              rowModelType='infinite'
              rowBuffer={bufferRows}
              onSelectionChanged={onSelectionChanged}
              cacheBlockSize={gridBlockSize}
              datasource={datasource}
              onRowSelected={(params) => {
                if (!params.node.selected) {
                  setSelectAllChecked(false);
                }
              }}
              paginationPageSize={gridBlockSize}
            /> : null}
          </div>
        </div>
        <div className="form-control row" /><div>&nbsp;</div>
        <div className="form-control row campaign-definition-form-control-row" >
            <CampaignDefinitionTextField fieldName="campaignId" label="Salesforce Campaign"
                                      value={payload.campaignId} fieldWidth="15em" fixedLabel={true}
                                      onChange={handleChange} error={errors.campaignId} />
            <div style={{ width: "5.2em" }}>&nbsp;</div>
            <CampaignDefinitionSelectField
              id="salesforceUserId"
              defaultValue="Select User"
              label="Salesforce User"
              fieldName="salesforceUserId"
              fieldWidth="18em"
              fieldHeight="2.7em"
              value={payload.salesforceUserId}
              options={salesforceUsers}
              error={errors.salesforceUserId}
              onChange={handleChange}
              fixedLabel="true"
            />
            <div style={{ width: "5.2em" }}>&nbsp;</div>
            <Button variant='outlined'
                    size="small"
                    onClick={submitSearchPayload} >Submit</Button>
        </div>
        <div className="form-control row campaign-definition-form-control-row" >
          <div className="campaign-definition-control-container" >
            <Button variant='outlined' onClick={() => validateCampaignId(payload.campaignId)} color='error' size="small">Validate Campaign</Button>
            <div style={{ width: "1em" }}>&nbsp;</div>
            <TextField
              variant="outlined"
              disabled
              value={payload.campaignName}
              size="small"
              inputProps={{ style : { width: "20em" }}}
            />
          </div>
          <div style={{ width: "0.5em" }}>&nbsp;</div>
          <FormControlLabel
            control={<Checkbox checked={payload.AddOtherSalesRepsOwnedLeadsToCampaign}
                               onChange={(e) =>
                                 handleChange(e.target.checked, "AddOtherSalesRepsOwnedLeadsToCampaign")}/>}
            label={<span style={{ fontSize: "0.8rem"}}>Add Other Sales Reps Owned Leads to Campaign</span>}
            labelPlacement="end"
          />
          <div style={{ width: "3.5em" }}>&nbsp;</div>
          <Button variant='outlined'
                  size="small"
                  onClick={() => setPayload(clearedPayload)}>Clear</Button>
        </div>
      </div>
    </>
  );
}

export default CampaignDefinitionResults;

