import { TextField } from '@mui/material';
import './ResearchTasksTextField.scss';

const ResearchTasksTextField = ({ fieldName, value, onChange, error, multiline }) => {

  const inputProps = {
    style : {
      padding: 0,
      ...(!multiline &&
        {height: "2.4em"}),
      ...(multiline &&
        {
          height: "1.5em",
          resize: 'vertical',
          overflow: 'auto'
        })
    }
  };
  
  return (
    <TextField
          inputProps={inputProps}
          fullWidth
          multiline={multiline}
          variant="outlined"
          value={value !== null ? value : ''}
          error={error}
          helperText={error}
          size="small"
          onChange={(e) => {onChange(e.target.value, fieldName)}}
          />
  );
}

export default ResearchTasksTextField;