
const rangeInputValidation = (minValue, maxValue) => {
    // Initialize variables that will store the error state and error message for each input
    let minError;
    let minErrorMessage;
    let maxError;
    let maxErrorMessage;


    // Calculate the error values for Min value
    if (minValue === "" || minValue == null){
        minError = false;
        minErrorMessage = "";
    } else if(isNaN(minValue)){
        minError = true;
        minErrorMessage = "Min must be a number";
    } else if (maxValue && Number(minValue) > Number(maxValue)){
        minError = true;
        minErrorMessage = "Min must be less than max";

    } else {
        minError = false;
        minErrorMessage = "";
    }
    // Calculate the error values for Max value
    if (maxValue === "" || maxValue == null){
        maxError = false;
        maxErrorMessage = "";
    } else if (isNaN(maxValue)){
        maxError = true;
        maxErrorMessage = "Max must be a number";
    } else if (minValue && Number(maxValue) < Number(minValue)){
        maxError = true;
        maxErrorMessage = "Max must be greater than min";
    } else {
        maxError = false;
        maxErrorMessage = "";
    }

    return [minError, minErrorMessage, maxError, maxErrorMessage];
}

export default rangeInputValidation;