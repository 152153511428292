import React from 'react';
import './Header.scss';
import { logo } from '../../../library/constants/imageConstants';
import { useKeycloak } from "@react-keycloak/web";
import { useDispatch } from 'react-redux';
import { redirect } from 'main/store/slices/utilSlice';
import {Link} from "@mui/material";

const Header = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    dispatch(redirect('/'));
    keycloak.logout();
  }

  return (
    <>
      <div className={`header-container`}>
        <div className="header-content-left">
          <img className="header-logo" src={logo} alt="Logo" />
        </div>
        <div className="header-content-right">
          <div className="header-box">
            {keycloak.authenticated && <>
              <Link onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</Link>
              <div><span style={{ fontWeight: "bold" }}>Logged in as: </span>{keycloak.tokenParsed.name}</div>
            </>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
