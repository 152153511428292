import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getOwnerResearchDetails} from "../../API/ownerResearchApi";
import {getOwnerPmtDetails} from "../../API/paymentAPI";

const NO_DATA_FOUND_MESSAGE = "No owner approved for research found";

const transformInboundPayload = (data) => {
  data.contacts.forEach((contact) => {
    contact.email_1 = contact.emails[0] || null;
    contact.email_2 = contact.emails[1] || null;
    contact.email_3 = contact.emails[2] || null;
    contact.email_4 = contact.emails[3] || null;
    contact.phone_1 = contact.phones[0]?.phoneNumber || null;
    contact.phone_2 = contact.phones[1]?.phoneNumber || null;
    contact.phone_3 = contact.phones[2]?.phoneNumber || null;
    contact.phone_4 = contact.phones[3]?.phoneNumber || null;
    contact.phone_1_type = contact.phones[0]?.phoneType || null;
    contact.phone_2_type = contact.phones[1]?.phoneType || null;
    contact.phone_3_type = contact.phones[2]?.phoneType || null;
    contact.phone_4_type = contact.phones[3]?.phoneType || null;
  });
  return data;
}
export const fetchOwnerResearchDetails = createAsyncThunk(
  'Owners/Research',
  async (payload, { dispatch }) => {
    const response = await getOwnerResearchDetails();
    if (NO_DATA_FOUND_MESSAGE === response.data.successMessage) {
      dispatch(setDataFound(false));
    }
    else {
      dispatch(setOwnerResearchDetails(transformInboundPayload(response.data.returnObject)));
    }
  }
);

export const fetchAvailableOwnerStats = createAsyncThunk(
  'Owners/Payments/Details',
  async (payload, { dispatch }) => {
    // todo -- determine if this is the correct api?
    const response = await getOwnerPmtDetails();
    dispatch(setOwnerResearchStats(response.data.returnObject));
  }
);

export const blankOwnerRecord = {
  "companyId": 0,
    "name": "",
    "address1": "",
    "address2": null,
    "city": "",
    "state": "",
    "zip": "",
    "website": null,
    "linkedIn": null,
    "industry": null,
    "researchType": null,
    "accountType": null,
    "companyAssets": [
    {
      "propertyId": 0,
      "fullAddress": "",
      "buildingSqFt": null,
      "buildingCount": null,
      "roofSqFt": null,
      "roofType": null,
      "notes": null,
      "hasNoBuildings": null
    }
  ],
    "contacts": [
    {
      "id": 0,
      "firstName": "",
      "lastName": "",
      "title": null,
      "company": null,
      "emails": [],
      "phones": [
        {
          "phoneNumber": "",
          "phoneType": null
        }
      ],
      "description" : null,
      "primary": false
    }
  ]
};

export const ownerResearchSlice = createSlice({
  name: 'ownerResearchDetails',
  initialState: {
    ownerData: blankOwnerRecord,
    dataFound: false,
    stats : {
        totalProperties: 0,
        updated: 0,
        remaining: 0,
        unpaid: 0,
        totalAvailableToUpdate: 4317,
    }
  },
  reducers: {
    setOwnerResearchDetails: (state, action) => {
        state.dataFound = true;
        state.ownerData = action.payload
    },
    setDataFound: (state, action) => { state.dataFound = action.payload },
    setOwnerResearchStats: (state, action) => { state.stats = action.payload },
    clearOwnerResearchDetails: (state, action) => { state.ownerData = blankOwnerRecord},
  }
});

export const { setOwnerResearchDetails,
                setOwnerResearchStats,
                clearOwnerResearchDetails ,
                setDataFound} = ownerResearchSlice.actions;

export const selectOwnerResearchDetails = state => state.ownerResearchDetails.ownerData;
export const selectTotalAvailableOwnerProps = state => state.ownerResearchDetails.stats.remaining;
export const selectDataFound = state => state.ownerResearchDetails.dataFound;

export default ownerResearchSlice.reducer;
