import { selectDownloadPacket, setDownloadPacket } from '../../../main/store/slices/uploadSlice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const FileDownload = () => {
    const dispatch = useDispatch();
    const downloadLinkRef = useRef();
    const [downloadUrl, setDownloadUrl] = useState('');

    const downloadPacket = useSelector(selectDownloadPacket);

    useEffect(() => {
        if (downloadPacket.data && downloadPacket.name?.length > 0) {
            setDownloadUrl(window.URL.createObjectURL(downloadPacket.data));
        }
    }, [downloadPacket.data, downloadPacket.name]);

    useEffect(() => {
        if (downloadUrl.length) {
            downloadLinkRef.current.click();
        }
    }, [downloadUrl]);

    return <>
        {downloadUrl?.length > 0 && <a download={downloadPacket.name} ref={downloadLinkRef} type="file" style={{display: 'none'}} href={downloadUrl}>This is content</a>}
    </>
}

export default FileDownload;