import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setToastMessage } from '../../../main/store/slices/utilSlice';
import { Button } from '@mui/material';
import toastTypes from '../../constants/toastTypes';
import './FileUploadInput.scss';

const FileUploadInput = ({label, value, onChange, fieldName, fileType}) => {
    const inputRef = useRef();
    const dispatch = useDispatch();
    const [uploadFile, setUploadFile] = useState(value);
    const handleInputChange = (e) => {
        if (e.target.files?.length > 0) {
            const tempFile = e.target.files[0];
            if (tempFile.type.toLowerCase().includes(fileType.toLowerCase())) {
                setValues(e.target.files[0])
            } else {
                dispatch(setToastMessage({
                    message: `You must upload a ${fileType} file.`,
                    type: toastTypes.warn
                }));
            }
        } else {
            setValues(false);
        }
    }

    const setValues = (payload) => {
        setUploadFile(payload);
        onChange(payload, fieldName);
    }
    return <>
        <label className="file-input-display">
            <div>{label}</div>
            <div className="file-input-wrapper">
                <input className="styled-input" type="text" value={uploadFile.name} onClick={() => inputRef.current.click()} readOnly={true} />
                <Button className="button" onClick={() => {inputRef.current.click()}} variant="outlined">Browse...</Button>
            </div>
        </label>
        <input ref={inputRef} id={`file-upload-${fieldName}`} className='styled-file-input' type="file" onChange={(e) => handleInputChange(e, fieldName)}  />
    </>
}

export default FileUploadInput