export const geographicSearchType = {
    EXACT: {
        id: 0,
        label: "Exact Match"
    },
    RADIUS: {
        id: 1,
        label: "Radius"
    }
}

export const geographicResultType ={
    PROPERTY: {
        id: 0,
        label: "Property Address"
    },
    OWNER: {
        id: 1,
        label: "Owner Address"
    }
}

export const searchRadiusDistances = [
    1,
    5,
    25,
    50,
    100,
    250
]


