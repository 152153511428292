import './ResearchTasks.scss';
import Button from '@mui/material/Button';
import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {useNavigate} from "react-router-dom-v5-compat";
import ConfirmDialog from "../../library/common/ConfirmDialog";
import ResearchTaskRow from "../../library/components/ResearchTasks/ResearchTaskRow";
import {getRoofTypes} from "../../main/API/leadMakerSearchApi";
import {uploadPropertyResearchDetails} from "../../main/API/propertyResearchApi";
import {
  fetchAvailablePropertyStats,
  fetchPropertyResearchDetails,
  selectPropertyResearchDetails,
  selectTotalAvailableProps,
  setPropertyResearchDetails,
} from "../../main/store/slices/assetResearchSlice";
import {useDispatch, useSelector} from "react-redux";

const ResearchTasks = () => {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [roofOptions, setRoofOptions] = useState([]);
  const payload = useSelector(selectPropertyResearchDetails);
  const propertyCount = useSelector(selectTotalAvailableProps);

  
  useEffect(() => {
    dispatch(fetchPropertyResearchDetails());
    dispatch(fetchAvailablePropertyStats());
  }, []);
  
  const submitPropertyResearch = async (payload) => {
    try {
      await uploadPropertyResearchDetails(payload);
    } catch(error) {
      console.error(`Error: ${error}`);
      return false;
    }
    return true;
  }
  
  const columns = [
    {
      field: 'fullAddress',
      headerName: 'Full Address',
    },
    {
      field: 'buildingSqFt',
      headerName: 'Building SF',
    },
    {
      field: 'buildingCount',
      headerName: '# of Buildings',
    },
    {
      field: 'roofSqFt',
      headerName: 'Roof Size',
    },
    {
      field: 'roofType',
      headerName: 'Roof Type',
    },
    {
      field: 'mapLink',
      headerName: 'Google Map',
    },
    {
      field: 'notes',
      headerName: 'Notes',
    },
    {
      field: 'hasNoBuildings',
      headerName: 'No Building',
    },
  ]
  
  const taskSchema = Yup.object().shape({
    hasNoBuildings: Yup.boolean(),
    roofSqFt: Yup.number()
      .transform((_, val) => (val !== "" ? Number(val) : null))
      .nullable(true)
      .typeError("Roof size must be digits only")
      .when('hasNoBuildings', {
        is: (hasNoBuildings) => !hasNoBuildings || hasNoBuildings == null,
        then: () => Yup.number().positive('Roof size must be a positive number')
            .integer('Roof size must be in whole numbers (sq ft)')
            .max(9999999, 'Roof size must be less than 10,000,000 sq ft')
      }),
    roofType: Yup.string()
      .nullable()
      .when('hasNoBuildings', {
        is: false,
        then: () => Yup.string().required("must have roof type")}),
    notes: Yup.string()
      .nullable(true)
      .max(4000, "Maximum of 4000 characters allowed"),
  });
  
  useEffect(() => {
    async function fetchRoofTypes() {
      const {data} = await getRoofTypes("");

      // allow a blank entry
      const results = [{
        id: 0,
        value: "",
      }];
      data.forEach((value) => {
        results.push({
          id: value.id,
          value: value.roofType,
        });
      });
      setRoofOptions(results);
    }
    fetchRoofTypes();
  }, []);
  
  const handleChange = (value, fieldName, index) => {
    if (index != null) {
      const newArray = payload.map((item, i) => {
        if (index === i) {
          return {...item, [fieldName] : value};
        } else {
          return item;
        }
      });

      dispatch(setPropertyResearchDetails(newArray));
    }
    else {
      alert('no index passed to change for field ' + fieldName + '-- error');
    }
  }
  
  const validateForm = () => {
    let tempErrors = [];
    payload.map((task, index) => {
      let thisTaskError = {};
      try {
        taskSchema.validateSync(task, { abortEarly: false });
      } catch (error) {
        thisTaskError = error.inner.reduce((acc, cur) => {
          acc[cur.path] = cur.message;
          return acc;
        }, {});
        if (thisTaskError) {
          tempErrors.push({...thisTaskError, "taskIndex" : index});
        }
      }
    })
    return tempErrors;
  }
  
  const updateAndExit = () => {
    updateForm()
      .then(ret => {
        if (ret) {
          exitPage();
        }
      });
  }
  
  const updateAndGetMore = () => {
    updateForm()
      .then(ret => {
        if (ret) {
          dispatch(fetchPropertyResearchDetails());
          dispatch(fetchAvailablePropertyStats());
        }
      });
    }
    
  const updateForm = async () => {
    let formErrors = validateForm();
    console.log("in save- errors are:", JSON.stringify(formErrors));
    setErrors(formErrors);
    if (!formErrors || (Object.keys(formErrors).length === 0)) {
      if (await submitPropertyResearch(payload)) {
        alert('Asset Research Saved!');
        return true;
      } else {
        return false;
      }
    } else {
      alert('Unable to save Asset Research -- see errors below');
      return false;
    }
  }

  const exitPage = () => {
    navigate("/");
  }
  
  
  return <div className="research-tasks-wrapper">
              <div className="flex-header">
                  <div className="component-title">
                      Asset Research
                  </div>
              </div>

              <div className="form">
                <div className="form-row">
                  <div className="form-control row   research-tasks-form-row"  >
                    <div className="inline-label">Total Properties Available to Update:</div>
                    <div>{propertyCount?.toLocaleString()}</div>
                  </div>
                </div>

                <div className="research-tasks-grid-container ">
                  {columns.map((column) => {
                    return <div className="form-control row research-tasks-form-control-row research-tasks-grid-header" >
                      {column.headerName}
                    </div>
                  })}
                {payload?.length > 0 && payload.map((taskData, index) => {
                  const taskErrors = errors?.find(({taskIndex}) => taskIndex === index);
                  return (
                    <ResearchTaskRow taskData={taskData}
                                     index={index}
                                     roofTypes={roofOptions}
                                     taskErrors={taskErrors}
                                     handleChange={handleChange} />
                  )
                })}
                </div>
              </div>
              <div className="form-row">
                <div className="form-control row  research-tasks-form-row" />
                <div className="form-control row  research-tasks-button-bar">
                  <Button variant='outlined'
                          className="button research-tasks-button"
                          onClick={updateAndGetMore}
                          >Update and Get 10 More Records
                  </Button>
                  <Button variant='outlined'
                          className="button research-tasks-button"
                          onClick={updateAndExit}
                          >Update and Exit</Button>
                  <Button variant='contained'
                          className="button research-tasks-button"
                          onClick={() => setConfirmDialogOpen(true)}
                          >Exit without Updating
                  </Button>
                </div>
              </div>
              <ConfirmDialog
                title="Exit without Saving?"
                open={confirmDialogOpen}
                setOpen={setConfirmDialogOpen}
                onConfirm={exitPage}
              >
                Are you sure you want to exit without saving?
              </ConfirmDialog>
  </div>
}

export default ResearchTasks;
