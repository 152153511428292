
import './ResearchTasksSelectField.scss';
import {Autocomplete, TextField} from "@mui/material";
import React from "react";

const ResearchTasksSelectField = ({ id, value, fieldWidth, fieldHeight, options, onChange, error }) => {
  
  const styleProps = () => {
    let styleProps = {};
    if (fieldWidth) {
      styleProps = {...styleProps, width: fieldWidth}
    }
    if (fieldHeight) {
      styleProps = {...styleProps, height: fieldHeight}
    }
    return styleProps;
  }
  
  return (
        <Autocomplete
          id={id}
          freeSolo={false}
          inputValue={value}
          // defaultValue={defaultValue}
          onChange={(e, newValue) => onChange(newValue?.value, id)}
          options={options}
          error={error}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(options) => options.value}
          filterOptions={(x) => x}
          renderInput={(params) =>
            <TextField error={error} helperText={error} {...params} size="small" />}
          sx={styleProps}
        />
  );
}

export default ResearchTasksSelectField;
