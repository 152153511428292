import { createSlice } from '@reduxjs/toolkit';
import {geographicSearchType, geographicResultType} from "../../../library/constants/GeographicSearchConstants";

const defaultState = {
    city: null,
    zipCode: null,
    usState: null,
    geographicSearchType: geographicSearchType.EXACT.id,
    geographicSearchRadius: 100,
    geographicResultType: geographicResultType.PROPERTY.id,

    accountType: null,
    companyAddressUSPSVerified: "All",
    primaryContactHasEmail: "All",
    primaryContactHasPhone: "All",
    portfolioValue: {min: null, max: null},
    totalPropertiesOwned: {min: null, max: null},

    buildingType: null,
    squareFootage: {min: null, max: null},
    yearBuilt: {min: null, max: null},
    acquisitionDate: {min: null, max: null},
    roofType: null,
    buildingAddressUSPSVerified: "All",
    ownerOccupied: "All",

    hailSize: {min: null, max: null},
    maxSizeStormDate: {min: null, max: null},
    mostRecentStormDate: {min: null, max: null},

    propertyInSalesforce: "All",
    previouslyExported: "All",
    dateAddedToDb: {min: null, max: null},
    roofResearchCompleted: "All",
    ownerResearchCompleted: null,
    source: {id: 0, label: 'All Sources'},

    gridBlockSize: 500
};

export const leadMakerSearchSlice = createSlice({
    name: 'leadMakerSearch',
    initialState: {...defaultState},
    reducers: {
        setUsState: (state, action) => {
            state.zipCode = null;
            state.city = null;
            state.usState = action.payload;
            state.geographicSearchType = geographicSearchType.EXACT.id;
        },
        setZipCode: (state, action) => {
            state.usState = null;
            state.city = null;
            state.zipCode = action.payload;
        },
        setCity: (state, action) => {
            state.usState = null;
            state.zipCode = null;
            state.city = action.payload;
        },
        setGeographicSearchType: (state, action) => {
            state.geographicSearchType = action.payload;
        },
        setGeographicSearchRadius: (state, action) => {
            state.geographicSearchRadius = action.payload;
        },
        setGeographicResultType: (state, action) => {
            state.geographicResultType = action.payload;
        },
        setAccountType: (state, action) => {
            state.accountType = action.payload;
        },
        setCompanyAddressUSPSVerified: (state, action) => {
            state.companyAddressUSPSVerified = action.payload;
        },
        setPrimaryContactHasEmail: (state, action) => {
            state.primaryContactHasEmail = action.payload;
        },
        setPrimaryContactHasPhone: (state, action) => {
            state.primaryContactHasPhone = action.payload;
        },
        setPortfolioValueMin: (state, action) => {
            state.portfolioValue.min = action.payload;
        },
        setPortfolioValueMax: (state, action) => {
            state.portfolioValue.max = action.payload;
        },
        setTotalPropertiesOwnedMin: (state, action) => {
            state.totalPropertiesOwned.min = action.payload;
        },
        setTotalPropertiesOwnedMax: (state, action) => {
            state.totalPropertiesOwned.max = action.payload;
        },
        setBuildingType: (state, action) => {
            state.buildingType = action.payload;
        },
        setSquareFootageMin: (state, action) => {
            state.squareFootage.min = action.payload;
        },
        setSquareFootageMax: (state, action) => {
            state.squareFootage.max = action.payload;
        },
        setYearBuiltMin: (state, action) => {
            state.yearBuilt.min = action.payload;
        },
        setYearBuiltMax: (state, action) => {
            state.yearBuilt.max = action.payload;
        },
        setAcquisitionDateMin: (state, action) => {
            state.acquisitionDate.min = action.payload;
        },
        setAcquisitionDateMax: (state, action) => {
            state.acquisitionDate.max = action.payload;
        },
        setRoofType: (state, action) => {
            state.roofType = action.payload;
        },
        setBuildingAddressUSPSVerified: (state, action) => {
            state.buildingAddressUSPSVerified = action.payload;
        },
        setOwnerOccupied: (state, action) => {
            state.ownerOccupied = action.payload;
        },
        setHailSizeMin: (state, action) => {
            state.hailSize.min = action.payload;
        },
        setHailSizeMax: (state, action) => {
            state.hailSize.max = action.payload;
        },
        setMaxSizeStormDateMin: (state, action) => {
            state.maxSizeStormDate.min = action.payload;
        },
        setMaxSizeStormDateMax: (state, action) => {
            state.maxSizeStormDate.max = action.payload;
        },
        setMostRecentStormDateMin: (state, action) => {
            state.mostRecentStormDate.min = action.payload;
        },
        setMostRecentStormDateMax: (state, action) => {
            state.mostRecentStormDate.max = action.payload;
        },
        setPropertyInSalesforce: (state, action) => {
            state.propertyInSalesforce = action.payload;
        },
        setPreviouslyExported: (state, action) => {
            state.previouslyExported = action.payload;
        },
        setDateAddedToDbMin: (state, action) => {
            state.dateAddedToDb.min = action.payload;
        },
        setDateAddedToDbMax: (state, action) => {
            state.dateAddedToDb.max = action.payload;
        },
        setRoofResearchCompleted: (state, action) => {
            state.roofResearchCompleted = action.payload;
        },
        setOwnerResearchCompleted: (state, action) => {
            state.ownerResearchCompleted = action.payload;
        },
        setSource: (state, action) => {
            state.source = action.payload;
        },
        setActiveSearchParams: (state, action) => {
            state.activeSearchParams = {...state, clear: false};
        },
        resetLeadMakerSearch: (state) => {
            return {...defaultState};
        },
        clearGrid: (state) => {
            state.activeSearchParams = {...state, clear: true};
        },
        setSelectAllCheckbox: (state, action) => {
            state.selectAllCheckbox = action.payload;
        },
        setGridBlockSize: (state, action) => {
            state.gridBlockSize = action.payload;
        }
    }
}
)

export const {
    setUsState,
    setZipCode,
    setCity,
    setGeographicSearchType,
    setGeographicSearchRadius,
    setGeographicResultType,
    setAccountType,
    setCompanyAddressUSPSVerified,
    setPrimaryContactHasEmail,
    setPrimaryContactHasPhone,
    setPortfolioValueMin,
    setPortfolioValueMax,
    setTotalPropertiesOwnedMin,
    setTotalPropertiesOwnedMax,
    setBuildingType,
    setSquareFootageMin,
    setSquareFootageMax,
    setYearBuiltMin,
    setYearBuiltMax,
    setAcquisitionDateMin,
    setAcquisitionDateMax,
    setRoofType,
    setBuildingAddressUSPSVerified,
    setOwnerOccupied,
    setHailSizeMin,
    setHailSizeMax,
    setMaxSizeStormDateMin,
    setMaxSizeStormDateMax,
    setMostRecentStormDateMin,
    setMostRecentStormDateMax,
    setPropertyInSalesforce,
    setPreviouslyExported,
    setDateAddedToDbMin,
    setDateAddedToDbMax,
    setRoofResearchCompleted,
    setOwnerResearchCompleted,
    setSource,
    resetLeadMakerSearch,
    clearGrid,
    setSelectAllCheckbox,
    setActiveSearchParams,
    setGridBlockSize
} = leadMakerSearchSlice.actions;

export const selectZipCode = state => state.leadMakerSearch.zipCode;
export const selectCity = state => state.leadMakerSearch.city;
export const selectUsState = state => state.leadMakerSearch.usState;
export const selectGeographicSearchType = state => state.leadMakerSearch.geographicSearchType;
export const selectGeographicSearchRadius = state => state.leadMakerSearch.geographicSearchRadius;
export const selectGeographicResultType = state => state.leadMakerSearch.geographicResultType;
export const selectAccountType = state => state.leadMakerSearch.accountType;
export const selectCompanyAddressUSPSVerified = state => state.leadMakerSearch.companyAddressUSPSVerified;
export const selectPrimaryContactHasEmail = state => state.leadMakerSearch.primaryContactHasEmail;
export const selectPrimaryContactHasPhone = state => state.leadMakerSearch.primaryContactHasPhone;
export const selectPortfolioValueMin = state => state.leadMakerSearch.portfolioValue.min;
export const selectPortfolioValueMax = state => state.leadMakerSearch.portfolioValue.max;
export const selectTotalPropertiesOwnedMin = state => state.leadMakerSearch.totalPropertiesOwned.min;
export const selectTotalPropertiesOwnedMax = state => state.leadMakerSearch.totalPropertiesOwned.max;
export const selectBuildingType= state => state.leadMakerSearch.buildingType || [];
export const selectSquareFootageMin = state => state.leadMakerSearch.squareFootage.min;
export const selectSquareFootageMax = state => state.leadMakerSearch.squareFootage.max;
export const selectYearBuiltMin = state => state.leadMakerSearch.yearBuilt.min || '';
export const selectYearBuiltMax = state => state.leadMakerSearch.yearBuilt.max || '';
export const selectAcquisitionDateMin = state => state.leadMakerSearch.acquisitionDate.min;
export const selectAcquisitionDateMax = state => state.leadMakerSearch.acquisitionDate.max;
export const selectRoofType = state => state.leadMakerSearch.roofType || [];
export const selectBuildingAddressUSPSVerified = state => state.leadMakerSearch.buildingAddressUSPSVerified;
export const selectOwnerOccupied = state => state.leadMakerSearch.ownerOccupied ;
export const selectHailSizeMin = state => state.leadMakerSearch.hailSize.min || '';
export const selectHailSizeMax = state => state.leadMakerSearch.hailSize.max || '';
export const selectMaxSizeStormDateMin = state => state.leadMakerSearch.maxSizeStormDate.min;
export const selectMaxSizeStormDateMax = state => state.leadMakerSearch.maxSizeStormDate.max;
export const selectMostRecentStormDateMin = state => state.leadMakerSearch.mostRecentStormDate.min;
export const selectMostRecentStormDateMax = state => state.leadMakerSearch.mostRecentStormDate.max;
export const selectPropertyInSalesforce = state => state.leadMakerSearch.propertyInSalesforce;
export const selectPreviouslyExported = state => state.leadMakerSearch.previouslyExported;
export const selectDateAddedToDbMin = state => state.leadMakerSearch.dateAddedToDb.min;
export const selectDateAddedToDbMax = state => state.leadMakerSearch.dateAddedToDb.max;
export const selectRoofResearchCompleted = state => state.leadMakerSearch.roofResearchCompleted ;
export const selectOwnerResearchCompleted = state => state.leadMakerSearch.ownerResearchCompleted;
export const selectSource = state => state.leadMakerSearch.source;
export const selectActiveSearchParams = state => state.leadMakerSearch.activeSearchParams;
export const selectSelectAllChecked = state => state.leadMakerSearch.selectAllCheckbox;
export const selectGridBlockSize = state => state.leadMakerSearch.gridBlockSize;
export default leadMakerSearchSlice.reducer;
