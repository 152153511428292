import GeographicSearchPanel from './GeographicSearchPanel';
import OwnerSearchPanel from './OwnerSearchPanel';
import BuildingSearchPanel from './BuildingSearchPanel';
import WeatherSearchPanel from "./WeatherSearchPanel";
import MiscSearchPanel from "./MiscSearchPanel";

const searchTabItems = [
    {
        name: "Geographic",
        component: GeographicSearchPanel
    },
    {
        name: "Owner",
        component: OwnerSearchPanel
    },
    {
        name: "Building",
        component: BuildingSearchPanel
    },
    {
        name: "Weather",
        component: WeatherSearchPanel
    },
    {
        name: "Misc",
        component: MiscSearchPanel
    }
];

export default searchTabItems;