import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getPropertyResearchDetails} from "../../API/propertyResearchApi";
import {getAssetPmtDetails} from "../../API/paymentAPI";

export const fetchPropertyResearchDetails = createAsyncThunk(
  'Properties/Research',
  async (payload, { dispatch }) => {
    const response = await getPropertyResearchDetails();
    dispatch(setPropertyResearchDetails(response.data.returnObject));
  }
);

export const fetchAvailablePropertyStats = createAsyncThunk(
  'Properties/Payments/Details',
  async (payload, { dispatch }) => {
    const response = await getAssetPmtDetails();
    dispatch(setPropertyResearchStats(response.data.returnObject));
  }
);

export const assetResearchSlice = createSlice({
  name: 'propertyResearchDetails',
  initialState: {
    propertyData: [
      {
        "propertyId": 0,
        "fullAddress": "-NO DATA-",
        "buildingSqFt": null,
        "buildingCount": null,
        "roofSqFt": 0,
        "roofType": "",
        "notes": "",
        "hasNoBuildings": false,
      },
    ],
    stats : {
        totalProperties: 0,
        updated: 0,
        remaining: 0,
        unpaid: 0,
        totalAvailableToUpdate: 4317,
    }
  },
  reducers: {
    setPropertyResearchDetails: (state, action) => { state.propertyData = action.payload },
    setPropertyResearchStats: (state, action) => { state.stats = action.payload}
  }
});

export const { setPropertyResearchDetails, setPropertyResearchStats } = assetResearchSlice.actions;

export const selectPropertyResearchDetails = state => state.propertyResearchDetails.propertyData;
export const selectTotalAvailableProps = state => state.propertyResearchDetails.stats.remaining;

export default assetResearchSlice.reducer;
