import {FormHelperText, TextField} from "@mui/material";
import "./Picklist.scss";
import Autocomplete from "@mui/material/Autocomplete";

const ObjectPicklist = ({ label, value, options, error, helperText, onChange}) => {
    return (
        <div>
            <div className="picklist-input">
                <div className="picklist-label">{label}</div>
                <Autocomplete id={label}
                              disablePortal
                              {...(value == null ? {value: null} : {value: value})}
                              options={options}
                              size="small"
                              error={error}
                              onChange={(event, newValue) => {
                                  console.log(newValue);
                                  onChange(newValue)}
                              }
                              isOptionEqualToValue={(option, value) => option === value || option.label === value.label}
                              sx={{width: "0.5"}}
                              renderInput={(params) => <TextField {...params} label={label} size="small" />}
                >
                </Autocomplete>
            </div>
            {error ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
        </div>
    )
}

export default ObjectPicklist;