import { trademarkString } from '../../../library/constants/trademark';
import React from 'react';
import { useLocation } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
  const location = useLocation();
  return (
    <>
      <div className={`styled-footer-container`}>
        <span className="footer-left">HailSolve</span>
        <span className="footer-right">{trademarkString}</span>
      </div>
    </>
  );
};

export default Footer;
