import "./MiscSearchPanel.scss";
import "./BuildingSearchPanel.scss";
import Picklist from "../SearchInputs/Picklist";
import DateRange from "../SearchInputs/DateRange";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateAddedToDbMax,
    selectDateAddedToDbMin,
    selectOwnerResearchCompleted,
    selectPreviouslyExported,
    selectPropertyInSalesforce,
    selectRoofResearchCompleted,
    selectSource,
    setDateAddedToDbMax,
    setDateAddedToDbMin, setOwnerResearchCompleted,
    setPreviouslyExported,
    setPropertyInSalesforce, setRoofResearchCompleted, setSource
} from "../../../../main/store/slices/leadMakerSearchSlice";
import {getResearchTypes} from "../../../../main/API/leadMakerSearchApi";
import {useEffect, useState} from "react";
import {getDataSourceList} from "../../../../main/API/uploadApi";
import ObjectPicklist from "../SearchInputs/ObjectPicklist";

const MiscSearchPanel = () => {
    const dispatch = useDispatch();
    const propertyInSalesforce = useSelector(selectPropertyInSalesforce);
    const previouslyExported = useSelector(selectPreviouslyExported);
    const dateAddedToDBMin = useSelector(selectDateAddedToDbMin);
    const dateAddedToDBMax = useSelector(selectDateAddedToDbMax);
    const roofResearchCompleted = useSelector(selectRoofResearchCompleted);
    const ownerResearchCompleted = useSelector(selectOwnerResearchCompleted);
    const source = useSelector(selectSource);

    // Initalize the options for the Owner Reasearch and Source fields
    // The default values are the only options initially
    const [researchTypeOptions, setResearchTypeOptions] = useState([ownerResearchCompleted]);
    const [sourceOptions, setSourceOptions] = useState([source]);

    // Fetch the options for research types in the Owner Research Completed field
    useEffect(() => {
        let ignore = false;
        const fetchResearchTypes = async () => {
            const response = await getResearchTypes()
            if(response.status === 200 && !ignore){
                setResearchTypeOptions(response.data.map(researchType => {
                    return {
                        id: researchType.id,
                        label:researchType.researchType
                    }}));
            }
        }
        fetchResearchTypes();

        return () => {
            ignore = true;
        };
    }, [])

    // Fetch the options for sources in the Source field
    useEffect(() => {
        let ignore = false;
        const fetchSources = async () => {
            const response = await getDataSourceList()
            if(response.status === 200 && !ignore){
                const returnedOptions = response.data.returnObject.map(source => {
                    return {
                        id: source.id,
                        label: source.name
                    }});
                // Use the spread operator since the "All Data Sources" default value isn't returned from the API
                setSourceOptions([{id: 0, label: 'All Sources'}, ...returnedOptions]);
            }
        }
        fetchSources();

        return () => {
            ignore = true;
        };
    }, []);

    return (
        <div className="misc-search-container">
            <div className="misc-search-column">
                <Picklist
                    label="Property in Salesforce"
                    value={propertyInSalesforce}
                    options={["All", "Y", "N"]}
                    onChange={(newValue) => dispatch(setPropertyInSalesforce(newValue))}/>
                <Picklist
                    label="Previously Exported"
                    value={previouslyExported}
                    options={["All", "Y", "N"]}
                    onChange={(newValue) => dispatch(setPreviouslyExported(newValue))}/>
                <DateRange
                    label="Date Added to DB"
                    minValue={dateAddedToDBMin}
                    maxValue={dateAddedToDBMax}
                    onMinChange={(newValue) => dispatch(setDateAddedToDbMin(newValue))}
                    onMaxChange={(newValue) => dispatch(setDateAddedToDbMax(newValue))}/>
            </div>
            <div className="misc-search-column">
                <Picklist
                    label="Roof Research Completed"
                    value={roofResearchCompleted}
                    options={["All", "Y", "N"]}
                    onChange={(newValue) => dispatch(setRoofResearchCompleted(newValue))}/>
                <ObjectPicklist
                    label="Owner Research Completed"
                    value={ownerResearchCompleted}
                    options={researchTypeOptions}
                    onChange={(newValue => dispatch(setOwnerResearchCompleted(newValue)))}
                    />
                <ObjectPicklist
                    label="Source"
                    value={source}
                    options={sourceOptions}
                    onChange={(newValue => dispatch(setSource(newValue)))}/>
            </div>
        </div>
    )
}

export default MiscSearchPanel;