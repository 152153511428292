import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {getAssetPmtDetails, getOwnerPmtDetails} from "../../API/paymentAPI";

export const getAssetPaymentDetails = createAsyncThunk(
    'Properties/Payments/Details',
    async (payload, { dispatch }) => {
        const response = await getAssetPmtDetails(payload);
        dispatch(setAssetPaymentDetails(response.data.returnObject));
    }
);

export const getOwnerPaymentDetails = createAsyncThunk(
  'Owners/Payments/Details',
  async (payload, { dispatch }) => {
    const response = await getOwnerPmtDetails(payload);
    dispatch(setOwnerPaymentDetails(response.data.returnObject));
    
  }
);


export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    assetPmtDetails: {
      totalProperties: 0,
      updated: 0,
      remaining: 0,
      unpaid: 0
    },
    ownerPmtDetails: {
      totalProperties: 0,
      updated: 0,
      remaining: 0,
      unpaid: 0
    },
  },
  reducers: {
    setAssetPaymentDetails: (state, action) => { state.assetPmtDetails = action.payload },
    setOwnerPaymentDetails: (state, action) => { state.ownerPmtDetails = action.payload },
  }
});

export const { setAssetPaymentDetails, setOwnerPaymentDetails } = paymentSlice.actions;

export const selectAssetPaymentDetails = state => state.payment.assetPmtDetails;
export const selectOwnerPaymentDetails = state => state.payment.ownerPmtDetails;

export default paymentSlice.reducer;
