import hailSolveApi from "../axios/hailSolveApi";

export const uploadPropertyAndContactInfo = async (payload) => {
    const response = await hailSolveApi({
        method: 'POST',
        url: 'Property/UploadFiles',
        data: payload,
        headers: {
            'Content-Type': 'form-data'
        }
    });
    return response;
}

export const getDataSourceList = async () => {
    const response = await hailSolveApi({
        method: 'GET',
        url: 'Property/GetDatasources'
    });
    return response;
}

export const getJobsList = async () => {
    const response = await hailSolveApi({
        method: 'GET',
        url: 'Property/GetImportJobs'
    });
    return response;
}

export const getJobDetails = async (jobId) => {
    const response = await hailSolveApi({
        method: 'GET',
        url: `Property/GetImportJob?jobId=${jobId}`
    });
    return response;
}

export const downloadFile = async (fileId) => {
    const response = await hailSolveApi({
        method: 'GET',
        url: `Property/GetFile?fileId=${fileId}`,
        responseType: 'blob'
    });
    return response;
}