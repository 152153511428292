
import './CampaignDefinitionSelectField.scss';
import React from "react";
import {FormHelperText} from "@mui/material";

const CampaignDefinitionSelectField = ({ id, label, value, fieldWidth, fieldHeight, options, error, onChange, defaultValue }) => {
  
  const styleProps = () => {
    let styleProps = {};
    if (fieldWidth) {
      styleProps = {...styleProps, width: fieldWidth}
    }
    if (fieldHeight) {
      styleProps = {...styleProps, height: fieldHeight}
    }
    return styleProps;
  }
  
  return (
    <>
      <div className="inline-label campaign-definition-select-label">{label}:</div>
      <div className="campaign-definition-select">
        <select
          key={id}
          className="campaign-definition-select-box"
          style={styleProps()}
          id={id}
          value={value}
          onChange={(e) => onChange(e.target.value, id)}
          >
            <option value={''} disabled hidden>{defaultValue}</option>
          {options?.length > 0 && options.map((option) => {
            return <option key={option.id} value={option.id}>{option.value}</option>
          })}
        </select>
        {error ? <FormHelperText error={error}>{error}</FormHelperText> : null}
      </div>
    </>
  );
}

export default CampaignDefinitionSelectField;