import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {priorities} from "../../../constants/picklists";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import "./ConfirmPriorityDialog.scss";

const ConfirmPriorityDialog = ( props ) => {
  const { open, setOpen, title, setPriority, onConfirm } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="priority-dialog"
    >
      <DialogTitle id="priority-dialog">{title}</DialogTitle>
      <DialogContent>
        <select
          className="priority-select-box"
          id="priority-select-box"
          onChange={(e) => setPriority(e.target.value)}
          defaultValue={priorities[2].id}
        >
          {priorities?.length > 0 && priorities.map((option) => {
            return <option key={option.id} value={option.id}>{option.value}</option>
          })}
        </select>
      </DialogContent>
      
      <DialogActions>
        <Button
          variant="outline"
          onClick={() => setOpen(false)}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          // color="default"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmPriorityDialog;