import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { downloadFile, getDataSourceList, getJobDetails, getJobsList, uploadPropertyAndContactInfo } from '../../API/uploadApi';

export const callUploadPropertyAndContactInfo = createAsyncThunk(
    'upload/uploadPropertyAndContactInfo',
    async (payload, { dispatch }) => {
        const response = await uploadPropertyAndContactInfo(payload);
        dispatch(setUploadSuccess(response.data.successMessage));
        dispatch(setActiveJobId(response.data.returnObject))
    }
);

export const callGetDataSourceList = createAsyncThunk(
  'upload/getDataSourceList',
  async (payload, {dispatch}) => {
    const response = await getDataSourceList();
    dispatch(setDataSourceList(response.data.returnObject));
  }
)

export const callGetJobsList = createAsyncThunk(
    'upload/getJobsList',
    async (payload, { dispatch }) => {
      const response = await getJobsList();
      dispatch(setJobsList(response.data.returnObject));
    }
);

export const callGetJobDetails = createAsyncThunk(
  'upload/getJobDetails',
  async (jobId, { dispatch }) => {
    const response = await getJobDetails(jobId);
    if (response.data.success) {
      dispatch(setJobDetails(response.data.returnObject));
    } else {
      dispatch(resetJobDetails());
    }
  }
);

export const callDownloadFile = createAsyncThunk(
  'upload/downloadFile',
  async ({fileId, fileName}, { dispatch }) => {
    const response = await downloadFile(fileId);
    dispatch(setDownloadPacket({name: fileName, data: response.data}));
  }
)

const defaultJobDetails = {
  steps: []
};


export const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    uploadSuccess: '',
    activeJobId: 0,
    jobsList: [],
    jobDetails: {...defaultJobDetails},
    dataSourceList: [],
    downloadPacket: {}
  },
  reducers: {
    setUploadSuccess: (state, action) => { state.uploadSuccess = action.payload },
    setActiveJobId: (state, action) => { state.activeJobId = action.payload },
    setJobsList: (state, action) => { state.jobsList = action.payload },
    setJobDetails: (state, action) => { state.jobDetails = action.payload },
    resetJobDetails: (state, action) => { state.jobDetails = {...defaultJobDetails} },
    setDataSourceList: (state, action) => { state.dataSourceList = action.payload },
    setDownloadPacket: (state, action) => { state.downloadPacket = action.payload }
  }
});

export const { setUploadSuccess, setActiveJobId, setJobsList, setJobDetails, resetJobDetails, setDataSourceList, setDownloadPacket } = uploadSlice.actions;

export const selectUploadSuccessMessage = (state) => state.upload.uploadSuccess;
export const selectActiveJobId = (state) => state.upload.activeJobId;
export const selectDataSourceList = state => state.upload.dataSourceList;
export const selectJobsList = state => state.upload.jobsList;
export const selectJobDetails = state => state.upload.jobDetails;
export const selectDownloadPacket = state => state.upload.downloadPacket;

export default uploadSlice.reducer;
