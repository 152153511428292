import React from 'react';
import { useKeycloak } from "@react-keycloak/web";
import { Route, Redirect, withRouter } from 'react-router-dom';

const PrivateRoute = withRouter(({ component: Component, ...rest }) => {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;
  // TODO: Apply permissions logic
  const componentCheck = props => {
    if (!isLoggedIn) {
      return <Redirect to="/" />
    } else if (isLoggedIn) {
      return <Route component={Component} {...rest}/>;
    }
  };
  return <Route {...rest} render={props => componentCheck(props)} />;
});

export default PrivateRoute;
