import React, {useEffect, useState} from "react";
import './Sidebar.scss';
import { SidebarItems } from "./SidebarItems";
import { NavLink } from "react-router-dom";
import { ReactComponent as MenuIcon} from 'assets/svg/Menu.svg';
import { useDispatch, useSelector } from "react-redux";
import { toggleSideBarExpanded } from "main/store/slices/utilSlice";
import { useKeycloak } from "@react-keycloak/web";
import { hasRolePermissions } from "../../keycloak/permissions";

function Sidebar({history, sideBarExpanded}, {defaultActive}) {
    const location = history.location;
    const dispatch = useDispatch();
    const lastActiveIndex1 = +localStorage.getItem("lastActiveIndex1");
    const [activeIndex1, setActiveIndex1] = useState(lastActiveIndex1 || defaultActive);

    const { keycloak, initialized } = useKeycloak();

    function changeActiveIndex1(newIndex) {
        localStorage.setItem("lastActiveIndex1", newIndex)
        setActiveIndex1(newIndex)
    }

    function getPath(path) {
        if (path.charAt(0) !== "/") {
            return  "/" + path;
        }
        return path;
    }

    useEffect(()=> {
        const activeItem1 = SidebarItems().findIndex(item=> getPath(item.route) === getPath(location.pathname))
        changeActiveIndex1(activeItem1);
    }, [location]);

    useEffect(() => {
        getWindowSize();
        const listener = window.addEventListener('resize', (e) => {
            getWindowSize();
        });
        return () => {
            window.removeEventListener('resize', listener);  
        }
    }, []);

    const getWindowSize = () => {
        dispatch(toggleSideBarExpanded(window.innerWidth > 800));
    }

    const handleToggleSidebar = () => {
        dispatch(toggleSideBarExpanded(!sideBarExpanded));
    }

    return (
        <>
            <div className={`sidebar-parent${sideBarExpanded ? ' expanded' : ''}`}>
                <div className="sidebar-item menu" onClick={handleToggleSidebar} onKeyDown={handleToggleSidebar} role={'toolbar'}>
                    <span className="sidebar-icon"><MenuIcon /></span>
                </div>
                <div className="divider no-margin-top"></div>
                {SidebarItems().map((item, index)=> {
                    if (hasRolePermissions(keycloak, item.requiredRoles)) {
                        return (
                          <NavLink to={item.route} key={item.route} activeClassName="active">
                              <div className={`sidebar-item ${index === activeIndex1 ? 'active' : ''}`} key={item.name}
                                   title={item.display}>
                                  {item.icon && <span className="sidebar-icon">{item.icon}</span>}
                                  {sideBarExpanded && <p>{item.name}</p>}
                              </div>
                          </NavLink>
                        );
                    }
                })}
            </div>
        </>
    );
}

export default Sidebar;
