import { createSlice } from '@reduxjs/toolkit';

export const utilSlice = createSlice({
  name: 'util',
  initialState: {
    redirectTo: '',
    sidebarExpanded: false,
    activeRequests: 0,
    toastMessage: {}
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    redirect: (state, action) => { state.redirectTo = action.payload },
    toggleSideBarExpanded: (state, action) => { state.sideBarExpanded = action.payload },
    setToastMessage: (state, action) => { state.toastMessage = action.payload },
    addFetch: (state) => { state.activeRequests++ },
    removeFetch: (state) => { state.activeRequests > 0 && state.activeRequests-- },
  }
});

export const { redirect, toggleSideBarExpanded, addFetch, removeFetch, setToastMessage } = utilSlice.actions;

export const selectRedirectTo = (state) => state.util.redirectTo;
export const selectSidebarExpanded = (state) => state.util.sideBarExpanded;
export const selectToastMessage = (state) => state.util.toastMessage;
export const selectIsLoading = (state) => state.util.activeRequests > 0;

export default utilSlice.reducer;
