import React, { useEffect, useState } from 'react';
import './MonitoringPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { callGetMonitoringData, selectMonitoringData } from '../../main/store/slices/monitorSlice';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import DateTemplate from '../../library/components/Templates/DateTemplate';
import TimeTemplate from '../../library/components/Templates/TimeTemplate';
import { MomentFormat } from '../../library/constants/momentConstants';

const MonitoringPage = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(callGetMonitoringData());
    }, []);

    const {
        monitoringData
    } = useSelector((state) => ({
        monitoringData: selectMonitoringData(state)
    }));

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            flex: 1.5,
            minWidth: 150,
            cellRender: DateTemplate
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1.5,
            minWidth: 150,
            cellRender: TimeTemplate
        },
        {
            field: 'source',
            headerName: 'Source',
            flex: 1.5,
            minWidth: 150
        },
        {
            field: 'size',
            headerName: 'Size',
            flex: 1.5,
            minWidth: 150
        },
        {
            field: 'notes',
            headerName: 'Notes',
            flex: 4.5,
            minWidth: 150
        },
    ]

    return <div className="monitoring-page-wrapper">
        <div className="flex-header">
            <div className="component-title">
                Property Monitoring
            </div>
        </div>
        <div className="form">
            <div className="form-row">
                <div className="form-control row">
                    <div className="inline-label">Report For:</div>
                    <div id="selected-date">{new Date().toDateString()}</div>
                </div>
                <div className="form-control">
                    <LocalizationProvider dateAdapter={DateAdapter} >
                        <DesktopDatePicker
                            label="Date desktop"
                            inputFormat="MM/DD/yyyy"
                            value={new Date()}
                            onChange={() => {}}
                            renderInput={(params) => <TextField {...params} variant="filled" />}
                        />
                    </LocalizationProvider>
                </div>
                {/* <div className="form-control date-picker row">
                    <div className="inline-label">Search Date:</div>
                    <input id="search-date" className="styled-input" type="date" onChange={() => {}} />
                </div> */}
            </div>
            {monitoringData?.length > 0 && monitoringData.map((siteData) => {
                return <div key={siteData.id} className="search-result-group">
                    <div className="form-row">
                        <div className="form-control row">
                            <div className="inline-label">Client Name:</div>
                            <div>{siteData.clientName}</div>
                        </div>
                        <div className="form-control row">
                            <div className="inline-label">Previous Storm Date:</div>
                            <div>{moment(siteData.previousStormDate).format(MomentFormat.DATE)}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-control row">
                            <div className="inline-label">Address:</div>
                            <div>{siteData.address}</div>
                        </div>
                    </div>
                    <div className="form-row">
                        <DataGrid
                            className="styled-data-grid"
                            autoHeight={true}
                            rows={siteData.stormData}
                            columns={columns}
                            disableColumnMenu={true}
                        ></DataGrid>
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default MonitoringPage;