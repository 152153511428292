
import './ResearchDetailSelectField.scss';
import {TextField, Autocomplete} from "@mui/material";
import React from "react";

const ResearchDetailSelectField = ({ id, object, fieldName, label, value, fieldWidth, fieldHeight,
                                     showDropDownArrow, options, onChange, fixedLabel, error }) => {

  function fieldLabel() {
    var className = "inline-label";
    if (fixedLabel) {
      className = "inline-label research-detail-select-label";
    }
    // console.log("returned data: ", <div className={className}>{label}:</div>)
    return <div className={className}>{label}:</div>

  }
  
  const styleProps = () => {
    let styleProps = {};
    if (fieldWidth) {
      styleProps = {...styleProps, width: fieldWidth}
    }
    if (fieldHeight) {
      styleProps = {...styleProps, height: fieldHeight}
    }
    return styleProps;
  }

  return (
    <>
      {fieldLabel()}
      <div >
        <Autocomplete
          id={id}
          freeSolo={false}
          forcePopupIcon={showDropDownArrow}
          inputValue={value || ""}
          error={error}
          onChange={(e, newValue) => onChange(newValue?.value, object, fieldName)}
          options={options}
          getOptionLabel={(options) => options.value}
          filterOptions={(x) => x}
          renderInput={(params) => <TextField {...params} error={error?.length > 0} label={error}  size="small" />}
          sx={styleProps}
        />
      </div>
      <div>&nbsp;&nbsp;</div>
    </>
  );
}

export default ResearchDetailSelectField;