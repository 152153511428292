
import './Payment.scss';
import Button from '@mui/material/Button';
import React, {useState} from 'react';
import * as Yup from "yup";
import PaymentsTextField from "./PaymentsTextField";
import { DatePicker } from '@mui/x-date-pickers';
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";


const Payment = ({titlePrefix, initialPayload, onSubmit, selector, updator }) => {
  
  const dispatch = useDispatch();
  const stats = useSelector(selector);
  const [payload, setPayload] = useState(initialPayload);
  const [errors, setErrors] = useState({});
  
  const paymentSchema = Yup.object().shape({
    // placeholder validation
    paidNumber: Yup.number()
      .typeError("Paid number is required and must be digits only")
      .integer("Paid number must be a whole number")
      .positive("Paid number must be a positive number"),
    paidDate: Yup.date()
      .typeError("A valid date must be entered"),
    paidNote: Yup.string()
      .max(255, "Note cannot exceed 255 characters"),
  });
  
  const handleChange = (value, fieldName) => {
    setPayload({...payload, [fieldName]: value });
    // console.log('setting payload for ', fieldName, ' to ', value);
  }
  
  const validateForm = () => {
    let newErrors = {};
    try {
      paymentSchema.validateSync(payload, { abortEarly: false });
    } catch (error) {
      // console.log('found errors: ', error);
      newErrors = error.inner.reduce((acc, cur) => {
        acc[cur.path] = cur.message;
        return acc;
      }, {});
    }
    return newErrors;
  }
  
  const callAPI = async () => {
    const newDate = moment(payload.paidDate).format('YYYY-MM-DD');
    // console.log('converted date: ', newDate);
    const response = await onSubmit({...payload,
      paidNumber: +payload.paidNumber,
      paidDate: newDate });
    if (response.status === 200 && response.data.success) {
      alert('Payment submitted successfully!');
      // console.log('data returned:', response.data);
      setPayload(initialPayload);
      dispatch(updator(response.data.returnObject));
    } else {
      alert('Error submitting payment!');
      console.log('Error submitting asset payment: ', response.errorMessage);
    }
  }
  const submitForm = () => {
    let formErrors = validateForm();
    setErrors(formErrors);
    if (!formErrors || (Object.keys(formErrors).length === 0)) {
      callAPI();
    } else {
      alert('Unable to submit Payment -- see errors below');
    }

  }
  
  return (
    <div className="payments-wrapper">
      <div className="flex-header">
        <div className="component-title">
        {titlePrefix} Payment Interface
        </div>
      </div>
    
      <div className="form">
        <div className="form-row">
          <div className="form-control row payments-form-row"/>
        </div>
        <div className="form-row payments-header-info">
          <div className="form-control row payments-form-row">
            <div className="inline-label payments-header-label">Properties in System:</div>
            <div>{stats?.totalProperties?.toLocaleString()}</div>
          </div>
          <div className="form-control row payments-form-row">
            <div className="inline-label payments-header-label">Total Updated:</div>
            <div>{stats?.updated?.toLocaleString()}</div>
          </div>
          <div className="form-control row payments-form-row">
            <div className="inline-label payments-header-label">Total Remaining:</div>
            <div>{stats?.remaining?.toLocaleString()}</div>
          </div>
          <div className="form-control row payments-form-row">
            <div className="inline-label payments-header-label">Total Unpaid:</div>
            <div>{stats?.unpaid?.toLocaleString()}</div>
          </div>
        </div>
      </div>
      <div className="form">
        <div className="form-control row payments-form-row" >&nbsp;</div>
        <div className="form-row">
          <div className="form-control row payments-form-control-row">
            <PaymentsTextField fieldName="paidNumber"
                               label="Paid Number"
                               value={payload.paidNumber}
                               fieldWidth="15em"
                               fixedLabel="true"
                               onChange={handleChange}
                               error={errors.paidNumber}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-control row payments-form-control-row">
            <div className="inline-label payments-text-label">Paid Date:</div>
            <div className="payments-date-field" >
              <DatePicker
                value={payload.paidDate ? Date.parse(payload.paidDate) : Date.now()}
                inputFormat="dd-MM-yyyy"
                onChange={(newValue) => {handleChange(newValue, "paidDate")}}
                slotProps={{
                  textField: {
                    size: 'small',
                    error: !!errors?.paidDate,
                    helperText: errors?.paidDate,
                    readOnly: true,
                    }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="form-control row payments-form-control-row">
            <PaymentsTextField fieldName="paidNote" label="Paid Note"
                               value={payload.paidNote}
                               fieldWidth="15em" fixedLabel="true"
                               errors={errors.paidNote}
                               onChange={handleChange}
                               error={errors?.paidNote}
            />
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-control row payments-form-row"/>
        <div className="form-control row payments-form-control-row">
          <div className="inline-label payments-header-label"></div>
          <div>&nbsp;&nbsp;</div>
          <Button variant='outlined' onClick={submitForm} className="button">Submit</Button>
        </div>
      </div>
    </div>
  )};

export default Payment;
