import React, {useEffect, useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';
import "./AutocompleteBox.scss"


const AutoCompleteBox = ({label, value, optionsFunction, selectionFunction, sx, preload, multiple, disabled, freeSolo}) => {
    // label: The label for the box
    const [options, setOptions] = useState([])
    
    useEffect(() => {
        if (preload) {
            fetchOptions("");
        }
    }, []);
    
    // fetchOptions will be called when the input changes to update the options
    // debounced to account for requests that will hit the wire
    const fetchOptions = React.useMemo(
        () =>
            debounce((input) => {
                optionsFunction(input, (newOptions) => {
                    setOptions(newOptions)});
            }, 350),
        [optionsFunction]
    );
    
    const displayValue = () =>  {
        return (value == null ? "" : value.label)
    }
  
    return (
        <div className="autocomplete-box">
            <div className="autocomplete-label">{label}:</div>
            <Autocomplete
                freeSolo={freeSolo}
                disablePortal
                disabled={disabled}
                multiple={multiple}
                {...(value == null ? {value: null} : {value: value})}
                options={options}
                onInputChange={(event, newValue) => {
                    selectionFunction(newValue);
                    fetchOptions(newValue);
                }}
                onChange={(event, newValue) => {
                    selectionFunction(newValue);
                }}
                isOptionEqualToValue={(option, value) => option === value || option.label === value.label}
                sx={sx}
                filterOptions={(x) => x}
                {...(freeSolo && { inputValue : displayValue() })}
                renderInput={(params) => <TextField {...params} label={label} size="small" />}
            />

        </div>
    );
}

export default AutoCompleteBox
