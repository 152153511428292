import React from 'react';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import Person from "@mui/icons-material/Person";
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ROUTES from '../../../library/constants/routeConstants';
import ROLES from "../../../library/constants/roleConstants";



export const SidebarItems = () => {

    // Note: access is granted if any of the required roles are met
    const mainItems = [
        {
            name: "Upload",
            display: "Upload",
            route: ROUTES.IMPORT,
            icon: <DriveFolderUploadOutlinedIcon />,
            requiredRoles: [ ROLES.HAILSOLVE_ADMIN ],
        },
        {
            name: "Upload Status",
            display: "Upload Status",
            route: ROUTES.STATUS,
            icon: <SummarizeOutlinedIcon />,
            requiredRoles: [ ROLES.HAILSOLVE_ADMIN ],
        },
        {
            name: "Monitoring",
            display: "Monitoring",
            route: ROUTES.MONITOR,
            icon: <MonitorHeartOutlinedIcon />,
            requiredRoles: [ "no-access" ],
        },
        {
            name: "LeadMaker Search",
            display: "LeadMaker Search",
            route: ROUTES.LEADMAKERSEARCH,
            icon: <BusinessOutlinedIcon />,
            requiredRoles: [ ROLES.LEAD_RESEARCHER, ROLES.HAILSOLVE_ADMIN ],
        },
        {
            name: "Asset Research",
            display: "Asset Research",
            route: ROUTES.RESEARCH_TASKS,
            icon: <BusinessOutlinedIcon />,
            requiredRoles: [ ROLES.ASSET_RESEARCHER, ROLES.ASSET_RESEARCHER_ADMIN, ROLES.HAILSOLVE_ADMIN ],
        },
        {
            name: "Owner Research",
            display: "Owner Research",
            route: ROUTES.RESEARCH_DETAIL,
            icon: <Person />,
            requiredRoles: [ ROLES.LEAD_RESEARCHER, ROLES.HAILSOLVE_ADMIN ],
        },
        {
            name: "Asset Payments",
            display: "Asset Payments",
            route: ROUTES.PAYMENTS_ASSET,
            icon: <LocalAtmOutlinedIcon />,
            requiredRoles: [ ROLES.PAYMENTS_DASHBOARD, ROLES.ASSET_RESEARCHER_ADMIN, ROLES.HAILSOLVE_ADMIN ],
        },
        {
            name: "Owner Payments",
            display: "Owner Payments",
            route: ROUTES.PAYMENTS_OWNER,
            icon: <MonetizationOnOutlinedIcon />,
            requiredRoles: [ "no-access" ],
        }
    ];

    return mainItems;
}
