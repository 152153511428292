import hailSolveApi from "../axios/hailSolveApi";

export const getMonitoringData = async (payload) => {
    // const response = await hailSolveApi({
    //     method: 'POST',
    //     url: 'Property/UploadFiles',
    //     data: payload,
    //     headers: {
    //         'Content-Type': 'form-data'
    //     }
    // });
    // return response;

    return {
        data: [
            {
                id: 1,
                clientName: 'ABC Company',
                previousStormDate: new Date(),
                address: "123 Main Street, Nashville, TN 37211",
                stormData: [
                    {
                        id: 1,
                        date: new Date(),
                        time: '11:30 PM',
                        source: 'NOAA Live',
                        size: '1.75\"',
                        notes: '7.77 Miles to the NW'
                    }
                ]
            }
        ]
    }
}