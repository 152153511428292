import {FormControl, FormHelperText, MenuItem, Select} from "@mui/material";
import "./Picklist.scss";

const Picklist = ({ label, value, options, error, helperText, onChange}) => {
    return (
        <FormControl>
            <div className="picklist-input">
                <div className="picklist-label">{label}</div>
                <Select id={label}
                        value={value}
                        size="small"
                        error={error}
                        onChange={(event) => onChange(event.target.value)}>
                    {options.map((option) => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                </Select>
            </div>
            {error ? <FormHelperText error={error}>{helperText}</FormHelperText> : null}
        </FormControl>
    )
}

export default Picklist;