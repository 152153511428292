import React from 'react';
import './LoadingIcon.scss';
import LoopIcon from '@mui/icons-material/Loop';
import { selectIsLoading } from 'main/store/slices/utilSlice';
import { useSelector } from 'react-redux';

const LoadingIcon = () => {
    const {
        isLoading
      } = useSelector((state) => ({
        isLoading: selectIsLoading(state)
      }));
    return <div className={`loading-icon-wrapper ${isLoading ? 'loading-indicator' : ''}`}>
        <LoopIcon className="icon" />
    </div>
}
export default LoadingIcon;
