import './LeadMakerSearch.scss';
import SearchTabs from '../../library/components/LeadMakerSearch/SearchParams/SearchTabs'
import CampaignDefinitionResults
  from "../../library/components/LeadMakerSearch/SearchResults/CampaignDefinitionResults";
import {useState} from "react";

const LeadMakerSearch = () => {
    const [datasourceFunction, setDatasourceFunction] = useState();
    return (
        <div className={"lead-maker-search-wrapper"}>
            <div className="flex-header">
                <div className="component-title">
                    LeadMaker Search
                </div>
            </div>
            <SearchTabs
                setDatasourceFunction={setDatasourceFunction}/>
            <CampaignDefinitionResults
                datasourceFunction={datasourceFunction}/>
        </div>
    );
}

export default LeadMakerSearch;