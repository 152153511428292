import hailSolveApi from "../axios/hailSolveApi";

export const getPropertyResearchDetails = async () => {
  const response = await hailSolveApi.get(`Properties/Research`);
  return response;
}

export const uploadPropertyResearchDetails = async (payload) => {
  const response = await hailSolveApi.post(`Properties/Research`, payload);
  return response;
}