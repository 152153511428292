import { cloneDeep } from 'lodash';
import { addFetch, removeFetch, setToastMessage } from 'main/store/slices/utilSlice';
import toastTypes from 'library/constants/toastTypes';
import axios from 'axios';

let store = {};
export const injectStore = (_store) => {
  store = _store;
}

const hailSolveApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

// Interceptor template
const attachAuthInterceptor = (token) => {
  hailSolveApi.interceptors.request.use(async config => {
    // Clone the request
    const clonedConfig = cloneDeep(config);

    // Apply app logic and modify the request
    clonedConfig.headers.common = {
      Authorization: `Bearer ${token}`,
      ttl: 60000
    };
    // Return the request
    return clonedConfig;
  });
};
// Logic to show the loading icon on any http request.
hailSolveApi.interceptors.request.use(function (config) {
  if(!config.noLoadingOverlay) {
    store.dispatch(addFetch());
  }
  return config
}, function (error) {
  return Promise.reject(error);
});
// logic to hide the loading icon on after all requests are resolved.
hailSolveApi.interceptors.response.use(function (response) {
  if(!response.config.noLoadingOverlay) {
    store.dispatch(removeFetch());
  }
  return response;
}, function (error) {
  store.dispatch(removeFetch());
  store.dispatch(setToastMessage({
    type: toastTypes.error,
    message: error.response.data.exceptionMessage
  }));
  return Promise.reject(error);
});

// TODO: Make and export detach method. Call on logout or session timeout.

export {
  attachAuthInterceptor
}

export default hailSolveApi;