
import ResearchDetailTextField from './ResearchDetailTextField';
import ResearchDetailSelectField from './ResearchDetailSelectField';
import '../../../pages/ResearchDetail/ResearchDetail.scss';
import './ResearchDetailContact.scss'
import { phoneTypes } from '../../constants/mockPicklists';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from "@mui/material/Button";
import React from "react";

const ResearchDetailContact = ({ contact, index, handleChange, errors, removeContact }) => {
  
  const handleContactChange = (value, object, fieldName) => {
    // console.log('firing change w/i contact component with index: ' + index);
    handleChange(value, "contacts", fieldName, index);
  }

  return (
    <>
    <div className="research-detail-asset">
      <div className="inline-label research-detail-label ">Contact {index + 1}</div>
      <div>
        <FormControlLabel
          value={index}
          control={<Radio />}
          checked={contact.primary}
          label="Primary"
          labelPlacement="end"
        />
      </div>
    </div>
    <hr/>
    <div className="form-row">&nbsp;</div>
    { contact?.isNew &&
    <div className="form-row">
      <Button
        variant='outlined'
        className="research-detail-contact-button"
        onClick={() => removeContact(index)}
      >Remove this Contact</Button>
    </div>
    }
    <div className="form-row">
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="firstName"
                                 label="First Name" value={contact.firstName} fieldWidth="21.8em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.firstName}  />
        <ResearchDetailTextField object="contact" fieldName="lastName"
                                 label="Last Name" value={contact.lastName} fieldWidth="21.8em"
                                  onChange={handleContactChange} error={errors?.lastName} />
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="title"
                                 label="Title" value={contact.title} fieldWidth="21.8em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.title}  />
        &nbsp;&nbsp;
        <ResearchDetailTextField object="contact" fieldName="company"
                                 label=" Company" value={contact.company} fieldWidth="21.8em"
                                 onChange={handleContactChange} error={errors?.company}  />
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="email_1"
                                 label="Email 1" value={contact.email_1} fieldWidth="21.8em" fixedLabel="true"
                                 onChange={handleContactChange}  error={errors?.email_1} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <ResearchDetailTextField object="contact" fieldName="phone_1"
                                 label="Phone 1" value={contact.phone_1} fieldWidth="10.6em"
                                 onChange={handleContactChange} error={errors?.phone_1} />
        &nbsp;&nbsp;
        <div className="form-control row research-detail-form-control-row"  >
          <ResearchDetailSelectField
            id="phone1Type"
            label="Type 1"
            showDropDownArrow={false}
            fieldName="phone_1_type"
            fieldWidth="8em"
            fieldHeight="2.3em"
            value={contact.phone_1_type}
            options={phoneTypes}
            error={errors?.phone_1_type}
            onChange={handleContactChange}
            // fixedLabel="true"
            />
        </div>
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="email_2"
                                 label="Email 2" value={contact.email_2} fieldWidth="21.8em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.email_2}  />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <ResearchDetailTextField object="contact" fieldName="phone_2"
                                 label="Phone 2" value={contact.phone_2} fieldWidth="10.6em"
                                 onChange={handleContactChange}  error={errors?.phone_2} />
        &nbsp;&nbsp;
        <div className="form-control row research-detail-form-control-row"  >
          <ResearchDetailSelectField
            id="phone1Type"
            label="Type 2"
            showDropDownArrow={false}
            fieldName="phone_2_type"
            fieldWidth="8em"
            fieldHeight="2.3em"
            value={contact.phone_2_type}
            options={phoneTypes}
            error={errors?.phone_2_type}
            onChange={handleContactChange}
            // fixedLabel="true"
            />
        </div>
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="email_3"
                                 label="Email 3" value={contact.email_3} fieldWidth="21.8em"  fixedLabel="true"
                                 onChange={handleContactChange}  error={errors?.email_3} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <ResearchDetailTextField object="contact" fieldName="phone_3"
                                 label="Phone 3" value={contact.phone_3} fieldWidth="10.6em"
                                 onChange={handleContactChange}  error={errors?.phone_3} />
        &nbsp;&nbsp;
        <div className="form-control row research-detail-form-control-row"  >
          <ResearchDetailSelectField
            id="phone1Type"
            label="Type 3"
            showDropDownArrow={false}
            fieldName="phone_3_type"
            fieldWidth="8em"
            fieldHeight="2.3em"
            value={contact.phone_3_type}
            options={phoneTypes}
            error={errors?.phone_3_type}
            onChange={handleContactChange}
            // fixedLabel="true"
            />
        </div>
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="email_4"
                                 label="Email 4" value={contact.email_4} fieldWidth="21.8em"  fixedLabel="true"
                                 onChange={handleContactChange}  error={errors?.email_4} />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <ResearchDetailTextField object="contact" fieldName="phone_4"
                                 label="Phone 4" value={contact.phone_4} fieldWidth="10.6em"
                                 onChange={handleContactChange}  error={errors?.phone_4} />
        &nbsp;&nbsp;
        <div className="form-control row research-detail-form-control-row"  >
          <ResearchDetailSelectField
            id="phone1Type"
            label="Type 4"
            showDropDownArrow={false}
            fieldName="phone_4_type"
            fieldWidth="8em"
            fieldHeight="2.3em"
            value={contact.phone_4_type}
            options={phoneTypes}
            error={errors?.phone_4_type}
            onChange={handleContactChange}
            // fixedLabel="true"
            />
        </div>
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="description"
                                 label="Description" value={contact.description} fieldWidth="50em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.description}  />
      </div>
    </div>
    <div className="form-row">&nbsp;</div>
    </>
  )
}

export default ResearchDetailContact;
