import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { redirect, selectRedirectTo } from 'main/store/slices/utilSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from "@react-keycloak/web";
import PrivateRoute from './PrivateRoute';
import ROUTES from 'library/constants/routeConstants';
import ImportPropertyAndContactForm from 'pages/ImportPropertyAndContacts/ImportPropertyAndContacts';
import MonitoringPage from 'pages/MonitoringPage/MonitoringPage';
import UploadStatus from 'pages/UploadStatus/UploadStatus';
import LeadMakerSearch from 'pages/LeadMaker/LeadMakerSearch'
import Layout from '../layout/Layout';
import { attachAuthInterceptor } from 'main/axios/hailSolveApi';
import ResearchDetail from '../../pages/ResearchDetail/ResearchDetail';
import { hasRoutePermissions } from '../keycloak/permissions';
import ResearchTasks from "../../pages/ResearchTasks/ResearchTasks";
import AssetPaymentsPage from "../../pages/AssetPaymentsPage/AssetPaymentsPage";
import OwnerPaymentsPage from "../../pages/OwnerPaymentsPage/OwnerPaymentsPage";

const Routes = () => {
    const dispatch = useDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [renderPage, setRenderPage] = useState(false);

    useEffect(() => {
        if (initialized && !keycloak.authenticated) {
            keycloak.login();
        }
    }, [initialized]);

    useEffect(() => {
        // attach authentication token to axios headers once resolved.
        if (keycloak.token) {
            attachAuthInterceptor(keycloak.token);
            setRenderPage(true);
        }
    },[keycloak.token])

    const {
        redirectTo
      } = useSelector((state) => ({
        redirectTo: selectRedirectTo(state)
      }));

    useEffect(() => {
        // cleanup redirect value after successful operation.
        if (redirectTo) {
            dispatch(redirect(''));
        }
    }, [redirectTo]);

    return (<>
        {redirectTo && <Redirect push to={redirectTo} />}
        <Switch>
            {renderPage && <Route render={props => (
                <Layout {...props}>
                    {hasRoutePermissions(keycloak, ROUTES.IMPORT) &&
                    < PrivateRoute exact path={ROUTES.IMPORT} component={ImportPropertyAndContactForm} />
                    }
                    {hasRoutePermissions(keycloak, ROUTES.STATUS) &&
                    <PrivateRoute exact path={ROUTES.STATUS} component={UploadStatus}/>
                    }
                    {hasRoutePermissions(keycloak, ROUTES.MONITOR) &&
                    <PrivateRoute exact path={ROUTES.MONITOR} component={MonitoringPage}/>
                    }
                    {hasRoutePermissions(keycloak, ROUTES.LEADMAKERSEARCH) &&
                    <PrivateRoute exact path={ROUTES.LEADMAKERSEARCH} component={LeadMakerSearch}/>
                    }
                    {hasRoutePermissions(keycloak, ROUTES.RESEARCH_TASKS) &&
                      <PrivateRoute exact path={ROUTES.RESEARCH_TASKS} component={ResearchTasks}/>
                    }
                    {hasRoutePermissions(keycloak, ROUTES.RESEARCH_DETAIL) &&
                    <PrivateRoute exact path={ROUTES.RESEARCH_DETAIL} component={ResearchDetail}/>
                    }
                    {hasRoutePermissions(keycloak, ROUTES.PAYMENTS_ASSET) &&
                      <PrivateRoute exact path={ROUTES.PAYMENTS_ASSET} component={AssetPaymentsPage} />
                    }
                    {hasRoutePermissions(keycloak, ROUTES.PAYMENTS_OWNER) &&
                      <PrivateRoute exact path={ROUTES.PAYMENTS_OWNER} component={OwnerPaymentsPage} />
                    }
                </Layout>
            )} />}
        </Switch>
    </>);
};

export default Routes;
