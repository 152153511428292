import hailSolveApi from "../axios/hailSolveApi";

const searchRequestConfig = {
    noLoadingOverlay: true
}

export const getZipCodes = async (zipCodeFilter) => {
    const response = await hailSolveApi.get(`ZipCodes?zipCodeFilter=${zipCodeFilter}`, searchRequestConfig);
    return response;
}

export const getCities = async (cityFilter) => {
    const response = await hailSolveApi.get(`Cities?cityFilter=${cityFilter}`, searchRequestConfig);
    return response;
}

export const getStates = async (stateFilter) => {
    const response = await hailSolveApi.get(`UsStates?stateFilter=${stateFilter}`, searchRequestConfig);
    return response;
}

export const getAccountTypes = async () => {
    const response = await hailSolveApi.get('AccountTypes');
    return response;
}
export const getBuildingTypes = async (buildingTypeFilter) => {
    const response = await hailSolveApi.get(`BuildingTypes?buildingTypesFilter=${buildingTypeFilter}`, searchRequestConfig);
    return response;
}

export const getIndustries = async () => {
    const response = await hailSolveApi.get('Industries');
    return response;
}
export const getRoofTypes = async (roofTypeFilter) => {
    const response = await hailSolveApi.get(`RoofTypes?roofTypeFilter=${roofTypeFilter}`, searchRequestConfig);
    return response;
}

export const getResearchTypes = async () =>{
    const response = await hailSolveApi.get('ResearchTypes');
    return response;
}

export const getSalesforceUsers = async (nameFilter) =>{
    const response = await hailSolveApi.get(`SalesforceUsers?nameFilter=${nameFilter}`);
    return response;
}

export const getCampaign = async (campaignId)  => {
    let response = "";
    try {
        response = await hailSolveApi.get(`Campaigns/${campaignId}`);
    } catch (error) {
        console.log('error status : ', error.response.status);
        if (error.response.status === 404) {
            return "";
        } else {
            // some unexpected error
            console.error(`Error getting Campaign - ${error.response.statusText} (${error.response.status})`);
            return "";
        }
    }
    return response?.data?.name;
}

export const submitLeadSearch = async (payload) => {
    const response = await hailSolveApi.post(`Leads/Search`, payload);
    return response;
}

export const submitCampaign = async (payload) => {
    const response = await hailSolveApi.post(`Leads`, payload);
    return response;
}

export const submitForResearch = async (payload) => {
    const response = await hailSolveApi.post(`Leads/SubmitForResearch`, payload);
    return response;
}

const downloadFilePayload = (file, fileName) => {
    // Because this is used with Ajax calls, we need to put the response blob data into a URL
    // put the URL into and anchor element, and then click that anchor.
    // Afterwords, we'll clean up the new elements that were created.
    const url = window.URL.createObjectURL(file);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName
    document.body.appendChild(anchor);
    anchor.click();
    anchor.remove();
    window.URL.revokeObjectURL(url);
}

export const exportPropertyLeadsAsCsv = async (payload) => {
    const response = await hailSolveApi.request(
        {url: `Leads/Properties/Export`, method: 'POST',
            data: payload, responseType: 'blob'})
        .then((response) => {
            downloadFilePayload(response.data, "Property Leads Search Export.csv");
        }
        );
    return response;
}

export const exportOwnerLeadsAsCsv = async (payload) => {
    const response = await hailSolveApi.request(
        {url: `Leads/Owners/Export`, method: 'POST',
            data: payload, responseType: 'blob'})
        .then((response) => {
                downloadFilePayload(response.data, "Owner Leads Search Export.csv");
            }
        );
    return response;
}