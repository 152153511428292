import "./SearchTabPanel.scss"
import {Button} from "@mui/material";
import {useDispatch} from "react-redux";
import {
    resetLeadMakerSearch,
    clearGrid,
    setSelectAllCheckbox,
    setActiveSearchParams,
    setGridBlockSize
} from "../../../../main/store/slices/leadMakerSearchSlice";

const SearchTabPanel =  ({selectedTab}) =>{
    const dispatch = useDispatch();
    const SelectedTab = selectedTab;
    return (
        <div className="search-panel-wrapper">
            <SelectedTab />
            <div className="search-controls">
                <Button variant="outlined"
                        onClick={()=> {
                            dispatch(resetLeadMakerSearch());
                            dispatch(clearGrid());
                            dispatch(setSelectAllCheckbox(true));
                        }}>
                    Clear
                </Button>
                <Button variant="outlined" onClick={() => {
                    dispatch(setGridBlockSize(500));
                    dispatch(setActiveSearchParams());
                }}>
                    Search
                </Button>
            </div>
        </div>
    )
}

export default SearchTabPanel;
