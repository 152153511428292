import Payment from "../../library/components/Payments/Payment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {useEffect} from "react";
import {uploadAssetPmt} from "../../main/API/paymentAPI";
import {useDispatch} from "react-redux";
import {
  getAssetPaymentDetails,
  selectAssetPaymentDetails,
  setAssetPaymentDetails
} from "../../main/store/slices/paymentSlice";

const AssetPaymentsPage = () => {
  
  const dispatch = useDispatch();
  
  const roofPaymentPayload = {
    paidNumber : "",
    paidDate : "",
    paidNote : "",
  }
  
  useEffect(() => {
    dispatch(getAssetPaymentDetails());
  }, []);
  
  const submitRoofPayment = async (payload) => {
    return await uploadAssetPmt(payload);
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} >
      <Payment titlePrefix="Asset Research"
               initialPayload={roofPaymentPayload}
               onSubmit={submitRoofPayment}
               selector={selectAssetPaymentDetails}
               updator={setAssetPaymentDetails}
      />
    </LocalizationProvider>
  )
}

export default AssetPaymentsPage;