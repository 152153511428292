import React, { useEffect, useState } from 'react';
import './UploadStatus.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectJobsList, selectJobDetails, callGetJobsList, callGetJobDetails, resetJobDetails, setJobsList, callDownloadFile } from '../../main/store/slices/uploadSlice';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import DateTimeTemplate from '../../library/components/Templates/DateTimeTemplate';
import BooleanTemplate from '../../library/components/Templates/BooleanTemplate';
import FileDownload from '../../library/components/FileDownload/FileDownload';

const UploadStatus = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [selectedJob, setSelectedJob] = useState([]);
    const [timeRemaining, setTimeRemaining] = useState(0);

    const {
        jobsList,
        jobDetails
    } = useSelector((state) => ({
        jobsList: selectJobsList(state),
        jobDetails: selectJobDetails(state)
    }));

    useEffect(() => {
        dispatch(callGetJobsList());
        const searchParams = new URLSearchParams(location.search);
        const jobId = searchParams.get('jobId');
        if (jobId) {
            setSelectedJob([jobId]);
        }
        return () => {
            dispatch(resetJobDetails());
            dispatch(setJobsList([]));
        }
    }, []);

    useEffect(() => {
        let interval;
        let countDown;
        if (jobDetails.id && !jobDetails.completed) {
            let seconds = 20;
            countDown = setInterval(() => {
                seconds -= 1;
                setTimeRemaining(seconds);
                if (seconds === 0) {
                    dispatch(callGetJobDetails(selectedJob[0]));
                    clearInterval(countDown);
                }
            }, 1000);
        }
        return () => {
            clearInterval(interval);
            clearInterval(countDown);
        }
    }, [jobDetails]);

    useEffect(() => {
        if (selectedJob[0]) {
            dispatch(resetJobDetails());
            dispatch(callGetJobDetails(selectedJob[0]));
        }

    }, [selectedJob]);

    const JobColumns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1.5,
            minWidth: 150
        },
        {
            field: 'datasource',
            headerName: 'Datasource',
            flex: 2,
            minWidth: 150
        },
        {
            field: 'completed',
            headerName: 'Completed',
            width: 130,
            renderCell: BooleanTemplate,
            align: 'center'
        },
        {
            field: 'noErrors',
            headerName: 'No Errors',
            minWidth: 130,
            renderCell: BooleanTemplate,
            align: 'center'
        }
    ]

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1.5,
            minWidth: 150
        },
        {
            field: 'description',
            headerName: 'Description',
            flex: 2,
            minWidth: 150
        },
        {
            field: 'startTime',
            headerName: 'Start',
            flex: 1,
            minWidth: 150,
            renderCell: DateTimeTemplate
        },
        {
            field: 'endTime',
            headerName: 'End',
            flex: 1,
            minWidth: 150,
            renderCell: DateTimeTemplate
        },
        {
            field: 'duration',
            headerName: 'Duration',
            minWidth: 150
        },
        
    ]

    const handleFileDownload = (fileId, fileName) => {
        dispatch(callDownloadFile({fileId, fileName}));
    }

    return <div className="upload-status-wrapper">
        <FileDownload />
        <div className="flex-header">
            <div className="component-title">
                Upload Status
            </div>
        </div>
        <div className="form">
            <div className="form-row">
                <DataGrid
                    className="styled-data-grid"
                    autoHeight={true}
                    pageSize={4}
                    rows={jobsList}
                    columns={JobColumns}
                    disableColumnMenu={true}
                    onSelectionModelChange={setSelectedJob}
                    selectionModel={selectedJob}
                ></DataGrid>
            </div>
        </div>
        {jobDetails?.steps?.length > 0 && 
        <>
            <div className="flex-header">
                <div className="component-title">
                    Details
                </div>
                {timeRemaining > 0 && <div>
                    Job Details will be automatically refreshed in {timeRemaining}...
                </div>}
            </div>
            <div className="form">
                <div className="form-row">
                    <DataGrid
                        className="styled-data-grid"
                        autoHeight={true}
                        rows={jobDetails.steps}
                        columns={columns}
                        disableColumnMenu={true}
                        hideFooter={true}
                        disableSelectionOnClick={true}
                    ></DataGrid>
                </div>
            </div>
        </>}
        <div className="file-download-wrapper">
            {jobDetails.inputFiles?.length > 0 && <div className='file-download-section'>
                <div className="flex-header">
                    <div className="component-title">
                        Input Files
                    </div>
                </div>
                {jobDetails.inputFiles.map((fileDetails) => 
                    <div
                        key={fileDetails.fileId}
                        className="download-file"
                        role="none"
                        onKeyDown={() => handleFileDownload(fileDetails.fileId, fileDetails.fileName)}
                        onClick={() => handleFileDownload(fileDetails.fileId, fileDetails.fileName)}>
                            <span>{fileDetails.fileName}</span>
                            <FileDownloadOutlinedIcon />
                    </div>
                )}
            </div>}
            <div className="file-download-section">
                <div className="flex-header">
                    <div className="component-title">
                        Output Files
                    </div>
                </div>
                {jobDetails.outputFiles?.length ? jobDetails.outputFiles.map((fileDetails) => 
                    <div
                        key={fileDetails.fileId}
                        className="download-file"
                        role="none"
                        onKeyDown={() => handleFileDownload(fileDetails.fileId, fileDetails.fileName)}
                        onClick={() => handleFileDownload(fileDetails.fileId, fileDetails.fileName)}>
                            <span>{fileDetails.fileName}</span>
                            <FileDownloadOutlinedIcon />
                    </div>
                ) : <div>(None)</div>}
            </div>
        </div>
        
    </div>
}

export default UploadStatus;