import { configureStore } from '@reduxjs/toolkit';
import uploadSlice from './slices/uploadSlice';
import utilSlice from './slices/utilSlice';
import monitorSlice from './slices/monitorSlice';
import leadMakerSearchSlice from './slices/leadMakerSearchSlice';
import paymentSlice from "./slices/paymentSlice";
import assetResearchSlice from "./slices/assetResearchSlice";
import ownerResearchSlice from "./slices/ownerResearchSlice";

export const store = configureStore({
  reducer: {
    util: utilSlice,
    upload: uploadSlice,
    monitor: monitorSlice,
    leadMakerSearch: leadMakerSearchSlice,
    payment: paymentSlice,
    propertyResearchDetails: assetResearchSlice,
    ownerResearchDetails: ownerResearchSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: process.env.NODE_ENV !== 'production'
});
