// this email regx validates emails as follows:
// email must be in the format local@domain.suffix
// local must consist of a-z,A-Z,0-9 and special characters !#$%&’*+/=?^_`{|}~-
// local can contain a . (dot) but must not start or end with one
// domain must start with a-z,A-Z, can contain a-z,A-Z and -
// suffix can be 2 or 3 characters and must be a-z,A-Z
export const EMAIL_REGX = new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@[a-zA-Z][a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/);
export const ZIPCODE_REGX = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);

const GOOGLE_MAP_URL = "https://www.google.com/maps/search/?api=1&query=";
export const toGoogleMapLink = (fullAddress) => {
  return encodeURI(GOOGLE_MAP_URL + fullAddress);
}

export const twoDecimalFormatter = (params) => {
  return Number.parseFloat(params.value).toFixed(2);
}

export const removeEmpty = (obj) =>  {
  const newObj = {};
  Object.keys(obj).forEach(function(k) {
    if (obj[k] && typeof obj[k] === "object") {
      const value = removeEmpty(obj[k]);
      if (Object.keys(value).length !== 0) {
        newObj[k] = removeEmpty(obj[k]);
      }
    } else if (obj[k] != null && obj[k] !== undefined) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}
