import hailSolveApi from "../axios/hailSolveApi";

export const getOwnerResearchDetails = async () => {
  const response = await hailSolveApi.get(`Owners/Research`);
  return response;
}

export const submitOwnerResearchDetails = async (payload) => {
  const response = await hailSolveApi.post(`Owners/Research`, payload);
  console.log('api response: ', response);
  return response;
}