import { useState } from 'react'
import searchTabItems from './searchTabItems';
import SearchTabPanel from './SearchTabPanel'
import {Tab, Tabs} from "@mui/material";


const SearchTabs =  () =>{
    const [tab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newTab) => {
        setSelectedTab(newTab);
    }

    return (
        <>
            <Tabs value={tab} onChange={handleTabChange}>
                {searchTabItems.map((item, index) =>
                    <Tab key={index} label={item.name} />)}
            </Tabs>
            <SearchTabPanel selectedTab={searchTabItems[tab].component} />
        </>
    )
}

export default SearchTabs;