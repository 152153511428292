import React from 'react';
import moment from 'moment';
import { MomentFormat } from '../../constants/momentConstants';

const DateTemplate = (params) => {
    const date = params.getValue(params.id, params.field);
    let formattedDate = ''
    if (date) {
        formattedDate = moment.utc(date).local().format(MomentFormat.DATE);
    }
    return <span className="date-template-cell">
        {formattedDate}
    </span>
}

export default DateTemplate;