import { TextField } from '@mui/material';
import './ResearchDetailTextField.scss';

const ResearchDetailTextField = ({ object, fieldName, label, value, fieldWidth, fixedLabel, onChange, error }) => {

  const inputProps = {
    style : {
      padding: 5,
      width: fieldWidth,
    }
  };

  function fieldLabel() {
    var className = "inline-label";
    if (fixedLabel) {
      className = "inline-label research-detail-text-label";
    }
    // console.log("returned data: ", <div className={className}>{label}:</div>)
    return <div className={className}>{label}:</div>

  }
      
  // console.log('props - ', inputProps);

  return (
    <>
      {/* <div className="inline-label research-detail-label">{label}:</div> */}
      {fieldLabel()}
      <div><TextField 
          fullWidth
          inputProps={inputProps}
          variant="outlined"
          value={value || ""}
          size="small"
          error={error}
          helperText={error}
          onChange={(e) => {onChange(e.target.value, object, fieldName)}}
          />
      </div>
      <div>&nbsp;</div>
    </>
  );
}

export default ResearchDetailTextField;