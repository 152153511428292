import "./BuildingSearchPanel.scss";
import { range } from 'lodash';
import AutoCompleteBox from "../SearchInputs/AutocompleteBox";
import NumericRange from "../SearchInputs/NumericRange";
import DateRange from "../SearchInputs/DateRange";
import { useDispatch, useSelector } from "react-redux";
import {
    setBuildingType,
    setSquareFootageMin,
    setSquareFootageMax,
    setYearBuiltMin,
    setYearBuiltMax,
    setAcquisitionDateMin,
    setAcquisitionDateMax,
    setRoofType,
    setBuildingAddressUSPSVerified,
    setOwnerOccupied,
    selectBuildingType,
    selectSquareFootageMin,
    selectSquareFootageMax,
    selectYearBuiltMin,
    selectYearBuiltMax,
    selectAcquisitionDateMin,
    selectAcquisitionDateMax,
    selectRoofType,
    selectBuildingAddressUSPSVerified,
    selectOwnerOccupied
} from "../../../../main/store/slices/leadMakerSearchSlice";
import Picklist from "../SearchInputs/Picklist";
import PicklistRange from "../SearchInputs/PicklistRange";
import {getBuildingTypes, getRoofTypes} from "../../../../main/API/leadMakerSearchApi";


const buildingTypeOptionsFetch = async (input, callback) => {
    const response = await getBuildingTypes(input);
    if (response.status === 200){
        callback(response.data.map(buildingType => {
            return {
                label: buildingType.buildingType,
                buildingUseCase: buildingType.buildingType,
                id: buildingType.id
            }
        })
        )
    }
}

const roofTypeOptionsFetch = async (input, callback) => {
    const response = await getRoofTypes(input);
    if (response.status === 200){
        callback(response.data.map(roofType => {
            return {
                label: roofType.roofType,
                roofType: roofType.roofType,
                id: roofType.id
            }
        })
        )
    }
}

const BuildingSearchPanel = () => {
    const dispatch = useDispatch();

    const buildingType = useSelector(selectBuildingType);
    const squareFootageMin = useSelector(selectSquareFootageMin);
    const squareFootageMax = useSelector(selectSquareFootageMax);
    const yearBuiltMin = useSelector(selectYearBuiltMin);
    const yearBuiltMax = useSelector(selectYearBuiltMax);
    const acquisitionDateMin = useSelector(selectAcquisitionDateMin);
    const acquisitionDateMax = useSelector(selectAcquisitionDateMax);
    const roofType = useSelector(selectRoofType);
    const buildingAddressUSPSVerified = useSelector(selectBuildingAddressUSPSVerified);
    const ownerOccupied = useSelector(selectOwnerOccupied);

    const currentYear = new Date().getFullYear();
    const yearBuiltRange = range(1900, currentYear+1)

    return (
        <div className="building-search-container">
            <div className="building-search-column">
                <AutoCompleteBox label="Building Type"
                                 value={buildingType}
                                 multiple={true}
                                 optionsFunction={buildingTypeOptionsFetch}
                                 preload={true}
                                 selectionFunction={(newValue) => dispatch(setBuildingType(newValue))}
                                 sx={{width: "0.5"}}/>
                <NumericRange label="Square Footage"
                              minValue={squareFootageMin}
                              maxValue={squareFootageMax}
                              onMinChange={(newValue) => dispatch(setSquareFootageMin(newValue))}
                              onMaxChange={(newValue) => dispatch(setSquareFootageMax(newValue))}/>
                <PicklistRange label="Year Built"
                               minOptions={yearBuiltRange}
                               minValue={yearBuiltMin}
                               onMinChange={(newValue) => dispatch(setYearBuiltMin(newValue))}
                               maxOptions={[...yearBuiltRange].reverse()}
                               maxValue={yearBuiltMax}
                               onMaxChange={(newValue) => dispatch(setYearBuiltMax(newValue))}/>
                <DateRange label="Acquisition Date"
                           minValue={acquisitionDateMin}
                           maxValue={acquisitionDateMax}
                           onMinChange={(newValue) => dispatch(setAcquisitionDateMin(newValue))}
                           onMaxChange={(newValue) => dispatch(setAcquisitionDateMax(newValue))}/>
            </div>
            <div className="building-search-column">
                <AutoCompleteBox label="Roof Type"
                                 value={roofType}
                                 multiple={true}
                                 optionsFunction={roofTypeOptionsFetch}
                                 preload={true}
                                 selectionFunction={(newValue) => dispatch(setRoofType(newValue))}
                                 sx={{width: "0.5"}}/>
                <Picklist label="Address USPS Verified"
                          value={buildingAddressUSPSVerified}
                          options={["All", "Y", "N"]}
                          onChange={(newValue) => dispatch(setBuildingAddressUSPSVerified(newValue))}/>

                <Picklist label="Owner Occupied"
                          value={ownerOccupied}
                          options={["All", "Y", "N"]}
                          onChange={(newValue) => dispatch(setOwnerOccupied(newValue))}/>
            </div>
        </div>
    )
}

export default BuildingSearchPanel;
