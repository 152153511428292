import './OwnerSearchPanel.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    setAccountType,
    setCompanyAddressUSPSVerified,
    setPrimaryContactHasEmail,
    setPrimaryContactHasPhone,
    setPortfolioValueMin,
    setPortfolioValueMax,
    setTotalPropertiesOwnedMin,
    setTotalPropertiesOwnedMax,
    selectAccountType,
    selectCompanyAddressUSPSVerified,
    selectPrimaryContactHasEmail,
    selectPrimaryContactHasPhone,
    selectPortfolioValueMin,
    selectPortfolioValueMax,
    selectTotalPropertiesOwnedMin,
    selectTotalPropertiesOwnedMax
} from "../../../../main/store/slices/leadMakerSearchSlice";
import NumericRange from "../SearchInputs/NumericRange";
import Picklist from "../SearchInputs/Picklist";
import {getAccountTypes} from "../../../../main/API/leadMakerSearchApi";
import {useEffect, useState} from "react";
import ObjectPicklist from "../SearchInputs/ObjectPicklist";

const OwnerSearchPanel = () => {
    const dispatch = useDispatch();
    const accountType = useSelector(selectAccountType);
    const companyAddressUSPSVerified = useSelector(selectCompanyAddressUSPSVerified);
    const primaryContactHasEmail = useSelector(selectPrimaryContactHasEmail);
    const primaryContactHasPhone = useSelector(selectPrimaryContactHasPhone);
    const portfolioValueMin = useSelector(selectPortfolioValueMin);
    const portfolioValueMax = useSelector(selectPortfolioValueMax);
    const totalPropertiesOwnedMin = useSelector(selectTotalPropertiesOwnedMin);
    const totalPropertiesOwnedMax = useSelector(selectTotalPropertiesOwnedMax);

    // Options for account types, set to current value as only option on initial render
    const [accountTypeOptions, setAccountTypeOptions] = useState([accountType]);

    // Fetch the options for account types
    useEffect(() => {
        let ignore = false;
        const fetchAccountTypes = async () => {
            const response = await getAccountTypes();
            if (response.status === 200 && !ignore) {
                setAccountTypeOptions(response.data.map(accountType =>
                {
                    return {
                        id: accountType.id,
                        accountType: accountType.accountType,
                        label: accountType.accountType
                    }
                }));
            }
        }
        fetchAccountTypes();
        return () => {
            ignore = true;
        }
    }, [])

    return (
        <div className="owner-search-container">
            <div className="account-contact-container">
                <ObjectPicklist label="Account Type"
                                value={accountType}
                                options={accountTypeOptions}
                                onChange={(value) => dispatch(setAccountType(value))}/>
                <Picklist label="Company Address USPS Verified"
                          value={companyAddressUSPSVerified}
                          options={["All", "Y", "N"]}
                          onChange={(value) => dispatch(setCompanyAddressUSPSVerified(value))}/>
                <Picklist label="Primary Contact Has Email"
                          value={primaryContactHasEmail}
                          options={["All", "Y", "N"]}
                          onChange={(value) => dispatch(setPrimaryContactHasEmail(value))}/>
                <Picklist label="Primary Contact Has Phone"
                          value={primaryContactHasPhone}
                          options={["All", "Y", "N"]}
                          onChange={(value) => dispatch(setPrimaryContactHasPhone(value))}/>
            </div>
            <div className="portfolio-property-container">
                <NumericRange label="Total Portfolio Value"
                              minValue={portfolioValueMin}
                              maxValue={portfolioValueMax}
                              onMinChange={(value) => dispatch(setPortfolioValueMin(value))}
                              onMaxChange={(value) => dispatch(setPortfolioValueMax(value))}/>
                <NumericRange label="Total Properties Owned"
                              minValue={totalPropertiesOwnedMin}
                              maxValue={totalPropertiesOwnedMax}
                              onMinChange={(value) => dispatch(setTotalPropertiesOwnedMin(value))}
                              onMaxChange={(value) => dispatch(setTotalPropertiesOwnedMax(value))}/>
            </div>
        </div>
    )
}

export default OwnerSearchPanel;