
const ROUTES = {
    IMPORT: '/imports',
    STATUS: '/status',
    MONITOR: '/monitoring',
    RESEARCH_DETAIL: '/research-detail',
    RESEARCH_TASKS: '/research-tasks',
    LEADMAKERSEARCH: '/lead-maker-search',
    PAYMENTS_ASSET: '/payments-asset',
    PAYMENTS_OWNER: '/payments-owner',
}

export default ROUTES;