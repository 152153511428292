import "./DateRange.scss";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const createSlotProps = (error, helperText) => {
    return (
        {
            textField: {
                size: "small",
                error: error,
                helperText: helperText,
                readOnly: true,
            },
            actionBar: {
                actions: ['clear'],
            }
        }
    )
}
const DateRange = ({ label, minValue, maxValue, onMinChange, onMaxChange }) => {
    let minError;
    let minErrorMessage;
    let maxError;
    let maxErrorMessage;

    if (!minValue && !maxValue){
        minError = false;
        minErrorMessage = "";
        maxError = false;
        maxErrorMessage = "";
    }
    else if (!minValue && maxValue){
        minError = true;
        minErrorMessage = "Min must also be set";
    }
    else if (minValue && !maxValue){
        maxError = true;
        maxErrorMessage = "Max must also be set";
    }
    else if (minValue > maxValue){
        minError = true;
        minErrorMessage = "Min must be less than max";
        maxError = true;
        maxErrorMessage = "Max must be greater than min";
    }
    else {
        minError = false;
        minErrorMessage = "";
        maxError = false;
        maxErrorMessage = "";
    }

    return(
        <div className="date-range">
            <div className="date-range-label">{label}:</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker slotProps={createSlotProps(minError, minErrorMessage)}
                            label="Min"
                            value={minValue}
                            onChange={(newValue) => onMinChange(newValue)}/>
                <div className="to-separator">to</div>
                <DatePicker slotProps={createSlotProps(maxError, maxErrorMessage)}
                            label="Max"
                            value={maxValue}
                            onChange={(newValue) => onMaxChange(newValue)}/>
            </LocalizationProvider>
        </div>
    )
}

export default DateRange;