import { SidebarItems } from "../layout/Sidebar/SidebarItems";

export const hasRolePermissions = (keycloak, requiredRoles) => {

  if (requiredRoles && requiredRoles.length > 0) {
    let hasPermission = false;
    for (const role of requiredRoles) {
      if (keycloak.hasRealmRole(role)) {
        hasPermission = true;
        break;
      }
    }
    return hasPermission;
  }
  // no required roles -- allow access to all
  return true;
}

export const hasRoutePermissions = (keycloak, thisRoute) => {
  const requiredRoles = SidebarItems().find(({ route }) => route === thisRoute).requiredRoles;
  return hasRolePermissions(keycloak, requiredRoles);
}


