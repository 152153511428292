import './GeographicSearchPanel.scss'
import AutoCompleteBox from "../SearchInputs/AutocompleteBox";
import {
    setZipCode,
    setUsState,
    setCity,
    setGeographicSearchType,
    setGeographicResultType,
    selectZipCode,
    selectCity,
    selectUsState,
    setGeographicSearchRadius, selectGeographicSearchType, selectGeographicResultType, selectGeographicSearchRadius
} from "../../../../main/store/slices/leadMakerSearchSlice"
import {useDispatch, useSelector} from "react-redux";
import {getZipCodes, getCities, getStates} from "../../../../main/API/leadMakerSearchApi";
import {FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from "@mui/material";
import {
    geographicResultType,
    geographicSearchType,
    searchRadiusDistances
} from "../../../constants/GeographicSearchConstants";

const zipCodeOptionsFetch = async (input, callback) => {
   const response = await getZipCodes(input)
   if (response.status === 200){
       callback(response.data.map(zipCode => zipCode.zipCode).slice(0, 30));
   }
}

const cityOptionsFetch = async (input, callback) => {
    const response = await getCities(input);
    if (response.status === 200){
        const cities = response.data.map(city => {
            return {label: `${city.city}, ${city.state}`, city: city.city, state: city.state}
        }).slice(0, 30);

        callback(cities);
    }
}

const stateOptionsFetch = async (input, callback) => {
    const response = await getStates(input);
    if (response.status === 200){
        callback(response.data.map(state => {
            return {label: state.state, abbreviation: state.stateAbbreviation}
        }))
    }
}

const parseToCityStateObject = (input) => {
    const cityState = input.split(",");
    const selection = {
        "label" : input,
        "city" : cityState[0]?.trim(),
        "state" : cityState[1]?.trim()
    }
    return selection;
}

const GeographicSearchPanel = () => {
    const zipCode = useSelector(selectZipCode);
    const city = useSelector(selectCity);
    const usState = useSelector(selectUsState);
    const selectedGeographicSearchType = useSelector(selectGeographicSearchType);
    const selectedAddressResultType = useSelector(selectGeographicResultType);
    const selectedGeographicSearchRadius = useSelector(selectGeographicSearchRadius);
    const dispatch = useDispatch();

    return (
        <div className="geographic-search-container">
            <div>
                <AutoCompleteBox
                    label="City"
                    value={city}
                    freeSolo={true} // allow typing directly into City field
                    disabled={usState != null || zipCode != null}
                    optionsFunction={cityOptionsFetch}
                    selectionFunction={(selection) => {
                        console.log('select was ', selection);
                        // if it's not an object, it's raw text that we need to parse
                        if (typeof selection === 'object')
                            dispatch(setCity(selection))
                        else
                            dispatch(setCity(parseToCityStateObject(selection)));
                    }}
                    sx={{width: "0.5"}}
                />
                <div className="or-field-separator">
                    OR
                </div>
                <AutoCompleteBox
                    label="Zip Code"
                    value={zipCode}
                    disabled={city != null || usState != null}
                    optionsFunction={zipCodeOptionsFetch}
                    selectionFunction={(selection) => dispatch(setZipCode(selection))}
                    sx={{width: "0.5"}}
                />
                <div className="or-field-separator">
                    OR
                </div>
                <AutoCompleteBox
                    label="State"
                    value={usState}
                    disabled={city != null || zipCode != null}
                    optionsFunction={stateOptionsFetch}
                    selectionFunction={(selection) =>{
                        console.log(selection);
                        dispatch(setUsState(selection))
                    }}
                    sx={{width: "0.5"}}
                />
            </div>
            <div>
                <FormControl>
                    <RadioGroup name="exact-or-radius" value={selectedGeographicSearchType}
                                onChange={(_, value) => dispatch(setGeographicSearchType(value))}>
                        <FormControlLabel value={geographicSearchType.EXACT.id} control={<Radio/>} label={geographicSearchType.EXACT.label}/>
                        <div className="or-field-separator">
                            OR
                        </div>
                        <div className="radius-select">
                        <FormControlLabel value={geographicSearchType.RADIUS.id}
                                          control={<Radio/>}
                                          label={geographicSearchType.RADIUS.label}
                                          {...(usState != null ? {disabled: true} : {})}
                        />
                            <FormControl {...(usState != null ? {disabled: true} : {})}>
                                <InputLabel id="radius-select">Radius</InputLabel>
                                <Select
                                    labelId="radius-select"
                                    id="radius-select"
                                    value={selectedGeographicSearchRadius}
                                    label="Radius"
                                    size="small"
                                    onChange={(event, value) => dispatch(setGeographicSearchRadius(event.target.value))}
                                >
                                    {searchRadiusDistances.map(distance => <MenuItem key={distance} value={distance}>{distance} Miles</MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                    </RadioGroup>
                </FormControl>
            </div>
            <div>
                <FormControl>
                    <RadioGroup name="property-or-owner-address"  value={selectedAddressResultType}
                                onChange={(_, value) => dispatch(setGeographicResultType(value))}>
                        <FormControlLabel value={geographicResultType.PROPERTY.id} control={<Radio/>} label={geographicResultType.PROPERTY.label}/>
                        <div className="or-field-separator">
                            OR
                        </div>
                        <FormControlLabel value={geographicResultType.OWNER.id} control={<Radio/>} label={geographicResultType.OWNER.label}/>

                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    );
}

export default GeographicSearchPanel;
