
const ROLES = {
  ASSET_RESEARCHER : "asset-researcher",
  ASSET_RESEARCHER_ADMIN : "asset-researcher-admin",
  LEAD_RESEARCHER : "lead-researcher",
  HAILSOLVE_ADMIN : "hailsolve-admin",
  PAYMENTS_DASHBOARD : "payments-dashboard",
}

export default ROLES;
