import React, { useEffect, useState } from 'react';
import FileUploadInput from '../../library/components/FileUploadInput/FileUploadInput';
import { Button } from '@mui/material';
import './ImportPropertyAndContacts.scss';
import { useDispatch, useSelector } from 'react-redux';
import { callGetDataSourceList, callUploadPropertyAndContactInfo, selectActiveJobId, selectDataSourceList, selectUploadSuccessMessage, setActiveJobId, setUploadSuccess } from '../../main/store/slices/uploadSlice';
import { redirect } from 'main/store/slices/utilSlice';
import ROUTES from 'library/constants/routeConstants';

const ImportPropertyAndContactForm = () => {
    const dispatch = useDispatch();

    const {
        uploadSuccess,
        dataSourceList,
        activeJobId
    } = useSelector((state) => ({
        uploadSuccess: selectUploadSuccessMessage(state),
        dataSourceList: selectDataSourceList(state),
        activeJobId: selectActiveJobId(state)
    }))

    const defaultFormValues = {
        jobName: '',
        datasourceId: '',
        property: false,
        contacts: false
    };

    const [uploadFiles, setUploadFiles] = useState(defaultFormValues);

    const [errors, setErrors] = useState({});

    const handleFormChange = (value, fieldName) => {
        setUploadFiles({...uploadFiles, [fieldName]: value});
    }

    useEffect(() => {
        dispatch(callGetDataSourceList());
        return () => {
            dispatch(setUploadSuccess(''));
            dispatch(setActiveJobId(0));
        }
    }, []);

    useEffect(() => {
        isFormValid(uploadFiles);
    }, [uploadFiles]);

    const isFormValid = (values) => {
        const tempErrors = {};
        if (!values.jobName) {
            tempErrors.jobName = 'Required';
        }
        if (!values.datasourceId) {
            tempErrors.datasourceId = 'Required';
        }
        if (typeof values.property !== 'object') {
            tempErrors.property = 'Required';
        }
        if (typeof values.contacts !== 'object') {
            tempErrors.contacts = 'Required';
        }
        setErrors(tempErrors);
    }

    const handleUploadFiles = () => {
        const payload = new FormData();
        for (const key in uploadFiles) {
            payload.append(key, uploadFiles[key]);
        }
        dispatch(callUploadPropertyAndContactInfo(payload));
    }

    const handleViewStatus = () => {
        if (activeJobId) {
            dispatch(redirect(`${ROUTES.STATUS}?jobId=${activeJobId}`));
        }
    }

    const handleBackClick = () => {
        setUploadFiles(defaultFormValues);
        dispatch(setUploadSuccess(''));
    }

    return <div className="import-form-wrapper">
        {!uploadSuccess?.length && <>
            <div className="flex-header">
                <div className="component-title">
                    Property and Contact Import
                </div>
            </div>
            <div className="info-text">
                Use this page to import the property and contact information from
                the current vendor.  Please provide a 'Mapping' file also to associate
                columns to Salesforce fields.  You can download a sample <span className="highlight-link">here</span>.
            </div>
            <div className="flex-header">
                <div className="component-title">
                    Upload Form
                </div>
                <div className="header-button-wrapper">
                    <Button className="button" onClick={handleUploadFiles} variant="contained" disabled={Object.keys(errors)?.length > 0}>Upload Files</Button>
                </div>
            </div>
            <div className="form">
                <div className="form-row">
                    <div className="form-control select">
                        <label htmlFor="data-name">Job Name</label>
                        <input className="styled-input" id="data-name" type="text" placeholder="Enter A Name" maxlength="255" value={uploadFiles.jobName} onChange={(e) => {handleFormChange(e.target.value, 'jobName')}} />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-control select">
                        <label htmlFor="storm-select">Datasource Name</label>
                        <select id="storm-select" className={`styled-input`} value={uploadFiles.datasourceId} onChange={(e) => handleFormChange(e.target.value, 'datasourceId')}>
                            <option value={''} disabled>Select A Datasource</option>
                            {dataSourceList?.length > 0 && dataSourceList.map((source) => {
                                return <option key={source.id} value={source.id}>{source.name}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-control">
                        <FileUploadInput
                            label={'Properties CSV'}
                            value={uploadFiles.property}
                            onChange={handleFormChange}
                            fieldName={'property'}
                            fileType="csv"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-control">
                        <FileUploadInput
                            label={'Contacts CSV'}
                            value={uploadFiles.contacts}
                            onChange={handleFormChange}
                            fieldName={'contacts'}
                            fileType="csv"
                        />
                    </div>
                </div>
            </div>
        </>}
        {uploadSuccess?.length > 0 && <>
            <div className="flex-header">
                <div className="component-title">
                    Upload Success
                </div>
                <div className="header-button-wrapper">
                    <Button className="button" onClick={handleBackClick} variant="outlined">Back</Button>
                    <Button className="button" onClick={handleViewStatus} variant="contained">View Status</Button>
                </div>
            </div>
            <div className="info-text">
                {uploadSuccess}
            </div>
        </>}
    </div>
}

export default ImportPropertyAndContactForm;