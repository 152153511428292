export const phoneTypes = [
  {
    id: "OFFICE",
    value: "Office",
  },
  {
    id: "DIRECT",
    value: "Direct",
  },
  {
    id: "MOBILE",
    value: "Mobile",
  },
  {
    id: "FAX",
    value: "Fax",
  },
  {
    id: "HOME",
    value: "Home",
  },
  {
    id: "OTHER",
    value: "Other",
  },
]