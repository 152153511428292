import PicklistRange from "../SearchInputs/PicklistRange";
import { range } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import DateRange from "../SearchInputs/DateRange";
import {
    setHailSizeMin,
    setHailSizeMax,
    setMaxSizeStormDateMin,
    setMaxSizeStormDateMax,
    setMostRecentStormDateMin,
    setMostRecentStormDateMax,
    selectHailSizeMin,
    selectHailSizeMax,
    selectMaxSizeStormDateMin,
    selectMaxSizeStormDateMax,
    selectMostRecentStormDateMin,
    selectMostRecentStormDateMax
} from "../../../../main/store/slices/leadMakerSearchSlice";

const WeatherSearchPanel = () => {
    const dispatch = useDispatch();

    const hailSizeMin = useSelector(selectHailSizeMin);
    const hailSizeMax = useSelector(selectHailSizeMax);
    const maxSizeStormDateMin = useSelector(selectMaxSizeStormDateMin);
    const maxSizeStormDateMax = useSelector(selectMaxSizeStormDateMax);
    const mostRecentStormDateMin = useSelector(selectMostRecentStormDateMin);
    const mostRecentStormDateMax = useSelector(selectMostRecentStormDateMax);

    const hailSizeOptions = range(0, 5.25, 0.25);

    return (
        <div className="weather-search-panel">
            <PicklistRange
                label="Max Size at location"
                minOptions={hailSizeOptions}
                minValue={hailSizeMin}
                onMinChange={(newValue) => dispatch(setHailSizeMin(newValue))}
                maxOptions={[...hailSizeOptions].reverse()}
                maxValue={hailSizeMax}
                onMaxChange={(newValue) => dispatch(setHailSizeMax(newValue))}/>
            <DateRange
                label="Max Size Storm Date"
                minValue={maxSizeStormDateMin}
                maxValue={maxSizeStormDateMax}
                onMinChange={(newValue) => dispatch(setMaxSizeStormDateMin(newValue))}
                onMaxChange={(newValue) => dispatch(setMaxSizeStormDateMax(newValue))}/>
            <DateRange
                label="Most Recent Storm Date"
                minValue={mostRecentStormDateMin}
                maxValue={mostRecentStormDateMax}
                onMinChange={(newValue) => dispatch(setMostRecentStormDateMin(newValue))}
                onMaxChange={(newValue) => dispatch(setMostRecentStormDateMax(newValue))}/>
        </div>
    )
}

export default WeatherSearchPanel;