import React from 'react';
import './App.scss';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from './main/keycloak/keycloak';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './main/routes/Routes';
import { Provider } from 'react-redux';
import { store } from './main/store/store';
import LoadingIcon from 'library/components/LoadingIcon/LoadingIcon';
import ToastMessage from 'library/components/ToastMessage/ToastMessage';
import { injectStore } from './main/axios/hailSolveApi';
import { CompatRouter } from "react-router-dom-v5-compat";

injectStore(store);

function App() {
  return (
    <ReactKeycloakProvider
      initOptions={{ onload: 'login-required' }}
      authClient={keycloak}
    >
      <React.StrictMode>
      <Provider store={store}>
        <LoadingIcon />
        <ToastMessage />
        <Router basename="/">
          <CompatRouter>
          <Routes />
          </CompatRouter>
        </Router>
      </Provider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
}

export default App;
