import React from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const BooleanTemplate = (params) => {
    const isTrue = params.getValue(params.id, params.field);
    return <div className={`completed-template-cell ${isTrue ? 'true' : 'false'}`}>
        {isTrue && <CheckCircleOutlinedIcon />}
        {!isTrue && <ErrorOutlineOutlinedIcon />}
    </div>
}

export default BooleanTemplate;