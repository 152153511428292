import hailSolveApi from "../axios/hailSolveApi";

export const getAssetPmtDetails = async () => {
  const response = await hailSolveApi.get(`Properties/Payments/Details`);
  return response;
}

export const uploadAssetPmt = async (payload) => {
  const response = await hailSolveApi.post(`Properties/Payments`, payload);
  return response;
}

export const getOwnerPmtDetails = async () => {
  const response = await hailSolveApi.get(`Owners/Payments/Details`);
  return response;
}

export const uploadOwnerPmt = async (payload) => {
  const response = await hailSolveApi.post(`Owners/Payments`, payload);
  return response;
}