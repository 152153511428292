import Payment from "../../library/components/Payments/Payment";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import {useEffect} from "react";
import {uploadOwnerPmt} from "../../main/API/paymentAPI";
import {useDispatch} from "react-redux";
import {
  getOwnerPaymentDetails,
  selectOwnerPaymentDetails,
  setOwnerPaymentDetails
} from "../../main/store/slices/paymentSlice";

const OwnerPaymentsPage = () => {
  
  const dispatch = useDispatch();
  
  const ownerPaymentPayload = {
    paidNumber : "",
    paidDate : "",
    paidNote : "",
  }
  
  useEffect(() => {
      dispatch(getOwnerPaymentDetails());
  }, []);
  
  const submitOwnerPayment = async (payload) => {
    return await uploadOwnerPmt(payload);
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} >
      <Payment titlePrefix="Owner Research"
               initialPayload={ownerPaymentPayload}
               onSubmit={submitOwnerPayment}
               selector={selectOwnerPaymentDetails}
               updator={setOwnerPaymentDetails}
      />
    </LocalizationProvider>
  )
}

export default OwnerPaymentsPage;