import React from 'react';
import './Layout.scss';
import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { selectSidebarExpanded } from '../store/slices/utilSlice';
import { useKeycloak } from '@react-keycloak/web';

const Layout = ({history, children}) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const {
    sideBarExpanded
} = useSelector((state) => ({
    sideBarExpanded: selectSidebarExpanded(state)
}));

  return (
    <div className="full-screen">
      {keycloak.authenticated && <div className="flex-container row">
        <Sidebar history={history} sideBarExpanded={sideBarExpanded} />
        <div className={`flex-container column stretch body${sideBarExpanded ? ' expanded' : ''}`}>
          <Header />
          <div className={`component-wrapper`}>
            {children}
          </div>
          <Footer />
        </div>
      </div>}
    </div>
  );
};

export default Layout;
