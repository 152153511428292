import { TextField } from '@mui/material';
import './CampaignDefinitionTextField.scss';

const CampaignDefinitionTextField = ({ fieldName, label, value, fieldWidth, onChange, error }) => {

  const inputProps = {
    style : {
      padding: 5,
      width: fieldWidth,
    }
  };

  return (
    <>
      <div className="inline-label ">{label}:</div>
      <div><TextField
          fullWidth
          inputProps={inputProps}
          variant="outlined"
          value={value}
          size="small"
          error={error}
          helperText={error}
          onChange={(e) => {onChange(e.target.value, fieldName)}}
          />
      </div>
      <div>&nbsp;</div>
    </>
  );
}

export default CampaignDefinitionTextField;