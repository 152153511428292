import "./PicklistRange.scss";
import Picklist from "./Picklist";
import rangeInputValidation from "./rangeInputValidation";

// Options and values must be of numeric type to allow for validation
const PicklistRange = ({ label, minValue, maxValue, minOptions, maxOptions, onMinChange, onMaxChange}) => {
    const [minError, minErrorMessage, maxError, maxErrorMessage] = rangeInputValidation(minValue, maxValue);

    return (
        <div className="picklist-range">
            <div className="picklist-range-label">{label}:</div>
            <Picklist value={minValue}
                      options={minOptions}
                      error={minError}
                      helperText={minErrorMessage}
                      onChange={(newValue) => onMinChange(newValue)}/>
            <div className="to-separator">to</div>
            <Picklist value={maxValue}
                      options={maxOptions}
                      error={maxError}
                      helperText={maxErrorMessage}
                      onChange={(newValue) => onMaxChange(newValue)}
            />
        </div>
    )
}

export default PicklistRange;