import "./NumericRange.scss"
import {FormControl} from "@mui/material";
import TextField from "@mui/material/TextField";
import rangeInputValidation from "./rangeInputValidation";

const NumericRange = ({label, minValue, maxValue, onMinChange, onMaxChange }) => {
    const [minError, minErrorMessage, maxError, maxErrorMessage] = rangeInputValidation(minValue, maxValue);
    return (
    <div className="numeric-range">
        <div className="numeric-range-label">{label}:</div>
        <FormControl>
            <TextField size="small"
                       variant="outlined"
                       label="Min"
                       {...(minValue == null ? {value: ''} : {value: minValue})}
                       error={minError}
                       helperText={minErrorMessage}
                       onChange={(event) => onMinChange(event.target.value)}>
            </TextField>
        </FormControl>
        <div className="to-separator">to</div>
        <FormControl>
            <TextField size="small"
                       variant="outlined"
                       label="Max"
                       {...(maxValue == null ? {value: ''} : {value: maxValue})}
                       error={maxError}
                       helperText={maxErrorMessage}
                       onChange={(event) => onMaxChange(event.target.value)}>
            </TextField>
        </FormControl>
    </div>
    )
}

export default NumericRange;